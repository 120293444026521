<template>
    <section class="grid" :class="{ first, last }" ref="grid">
        <slot class="float-right"></slot>

        <keep-alive v-if="grid.title">
            <component :is="gridTitleComponent">
                <span v-html="grid.title"></span>
            </component>
        </keep-alive>

        <!--
            Nuxt goes crazy when having both v-if and v-html directives on the same element. Therefore nested
        -->
        <template v-if="grid.content">
            <div v-html="grid.content" class="grid-content"></div>
        </template>

        <ContentTypes v-else-if="grid.jsonData" :data="grid.jsonData" />

        <template v-else>
            <!-- Mobile grids -->
            <media-only v-if="!noSwiper" mobile class="grid-mobile-wrapper">
                <Swiper :options="swiperOptions" class="grid-blocks-mobile">
                    <div
                        class="swiper-slide"
                        v-for="(block, index) in grid.blocks"
                        :key="getBlockKey(index) + 'mobile'"
                    >
                        <GridItem :block="block" ref="swipeItems" :highest="index < 3 && first"></GridItem>
                    </div>

                    <slot name="loadMore" v-if="canLoadMore" :inProgress="isLoadingMore">
                        <div class="swiper-slide">
                            <GridLoadMore :inProgress="isLoadingMore" @loadMore="loadMoreOffers"> </GridLoadMore>
                        </div>
                    </slot>

                    <template v-slot:after>
                        <div v-show="grid.blocks.length" class="swiper-pagination"></div>
                    </template>
                </Swiper>
            </media-only>

            <media-only :desktop="!noSwiper" class="grid-desktop-wrapper">
                <div class="grid-blocks" :style="style">
                    <GridItem
                        v-for="(block, index) in grid.blocks"
                        :key="getBlockKey(index, block)"
                        :block="block"
                        :allow-resizing="allowResizing"
                        :highest="index < 3 && first"
                    >
                    </GridItem>
                </div>
                <slot name="loadMore" v-if="canLoadMore" :inProgress="isLoadingMore">
                    <GridLoadMore :inProgress="isLoadingMore" @loadMore="loadMoreOffers"> </GridLoadMore>
                </slot>
            </media-only>
        </template>
    </section>
</template>

<script>
import GridItem from '@/components/grid/GridItem';
import Grid from '@/models/Grid';
import GridTitle from '@/components/grid/GridTitle';
import Swiper from '@/components/swiper/Swiper';
import GridLoadMore from '@/components/grid/GridLoadMore';

export default {
    name: 'Grid',
    components: {
        GridItem,
        GridTitle,
        Swiper,
        GridLoadMore, //can't be async, otherwise loadMore slide can be unrecognized by swiper
        GridTitleReisereporter: () =>
            import(/* webpackChunkName: "reisereporter" */ '@/themes/reisereporter/components/grid/GridTitle'),
        GridTitleKIU: () => import(/* webpackChunkName: "kiu" */ '@/themes/kurzinurlaub/components/KIUGridTitle.vue'),
        ContentTypes: () => import(/* webpackChunkName: "contentTypes" */ '@/components/grid/ContentTypes')
    },
    data() {
        return {
            isLoadingMore: false
        };
    },
    props: {
        grid: {
            type: [Grid, Object],
            required: true
        },
        first: Boolean,
        last: Boolean,
        allowResizing: Boolean,
        moreItemsUrl: {
            type: String,
            default: '/shop/cms/grid-content'
        },
        /**
         * Optional external pagination logic
         * @property { () => Promise } [paginationAsyncFunc]
         */
        paginationAsyncFunc: Function,
        willUpdate: Boolean
    },
    computed: {
        style() {
            return this.grid.blocksPerRow
                ? `grid-template-columns: repeat(${this.grid.blocksPerRow}, minmax(0, 1fr))`
                : '';
        },
        gridTitleComponent() {
            if (this.$store.state.theme === 'kurzinurlaub') {
                return 'GridTitleKIU';
            }
            if (this.$store.state.theme === 'reisereporter') {
                return 'GridTitleReisereporter';
            }
            return 'GridTitle';
        },
        canLoadMore() {
            return this.grid.page < this.grid.numberOfPages;
        },
        swiperOptions() {
            return {
                slidesPerView: 'auto',
                observer: this.$cmsTool || this.canLoadMore || this.willUpdate, //needed for the admin-cms. Whenever user updates grid items, swiper should be updated
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 3
                }
            };
        },
        noSwiper() {
            return this.$store.state.ident === 'kurzinurlaub';
        }
    },
    methods: {
        getBlockKey(blockIndex) {
            return `${this.grid.id}block${blockIndex}`;
        },
        async loadMoreOffers() {
            this.isLoadingMore = true;

            if (this.paginationAsyncFunc && typeof this.paginationAsyncFunc === 'function') {
                try {
                    await this.paginationAsyncFunc();
                } finally {
                    this.isLoadingMore = false;
                }
            } else {
                const nextPage = this.grid.page + 1;
                try {
                    const { data: grid } = await this.$axios.get(`${this.moreItemsUrl}/${this.grid.id}/${nextPage}`);
                    this.$store.dispatch('updateGrid', grid);
                } catch (error) {
                    if (process.env.NODE_ENV === 'development') {
                        console.log(error);
                    }
                } finally {
                    this.isLoadingMore = false;
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
@use 'sass:map';
@use '@/styles/variables';
@use '@/styles/bootstrap/breakpoints' as breakpoints;

.grid {
    margin-top: 50px;

    &.first {
        margin-top: 25px;
    }

    &:last-of-type {
        margin-bottom: 25px;
    }

    ::v-deep .grid-title,
    .grid-content {
        grid-column: 1/-1;
        margin-bottom: 20px;
        background-color: var(--body-bg);
    }

    $shadow-blur: map-get(variables.$grid, 'tile-shadow-blur');

    //sequential grids should respect top grid's blocks shadow blur
    & + .grid {
        margin-top: 50px - $shadow-blur;
    }

    .grid-blocks {
        display: grid;
        grid-gap: variables.$grid-gutter-width;
        grid-template-columns: repeat(3, minmax(0, 1fr)); //test minmax(0, 1fr) instead of just 1fr

        &:not(:empty) {
            padding-bottom: $shadow-blur; //to prevent bottom shadow cut
        }

        @include breakpoints.media-breakpoint-only(xl) {
            grid-auto-rows: var(--grid-tile-height-xl);
        }

        @include breakpoints.media-breakpoint-only(lg) {
            grid-auto-rows: var(--grid-tile-height-lg);
            grid-gap: 20px;
        }

        @include variables.ie {
            display: flex;
            flex-wrap: wrap;
            margin: -(variables.$grid-padding);
        }
    }

    .grid-blocks-mobile {
        margin: 0 (-(variables.$grid-padding));
        padding: 0 variables.$grid-padding;

        //!important: prevents grid mobile items from occupying uncontrolled width
        //Cannot use on swiper itself, since there are cases when swiper-wrapper shouldn't be flexible (tabs e.g.)
        ::v-deep .swiper-wrapper {
            display: flex;

            .grid-block {
                width: map-get(
                    variables.$grid,
                    'tile-width-mobile'
                ); //for tablets with lg media query and landscape orientation
            }
        }

        ::v-deep .swiper-pagination {
            height: 15px;
            bottom: 0;
            padding: 0 15px;
            left: 50%;
            transform: translateX(-50%) !important;

            .swiper-pagination-bullet {
                height: 15px;
                margin: 0 5px;
                border-radius: 0 !important;
                outline: 0;
                width: 30px;
                padding-top: 10px;
                transform: none !important;
                background: linear-gradient(0deg, var(--body-color) 33%, transparent 33%);

                &.swiper-pagination-bullet-active {
                    background: linear-gradient(0deg, var(--primary) 33%, transparent 33%);
                }
            }
        }

        .swiper-slide {
            width: auto;
            margin-right: variables.$grid-padding;
        }
    }
}
</style>
