import priceModes from '@/models/priceModes';
import fetchResource from '@/utils/fetchResource';
import { ErrorTypes } from '@/models/AjaxError';
import { domainToIdent, identToPricemode, identToTheme } from '@/themes/domainMapping';

const commitDelay = f => {
    /**
     * When navigating to the same dynamic route, like product details or voucher details,
     * nuxt firstly fetches async data and commits a mutation, thus reevaluating all computed
     * properties because the data has changed, even though the page component hasn't been destroyed yet.
     * This causes a side effect of child components rerender during their destroy phase (e.x. LeafletMap).
     * commitDelay forces nuxt to execute data replacement after beforeDestroy hook
     * via executing commit at the end of event loop
     * https://github.com/nuxt/nuxt.js/issues/4132
     */
    if (process.client) {
        setTimeout(() => {
            f();
        }, 0);
    } else {
        f();
    }
};

export const state = () => {
    // do not use localStorage or sessionStorage to init state values, as this  breaks things in incognito mode or when cookies are disabled
    return {
        //page data containers
        globalMeta: {},
        translations: {},
        home: {},
        category: {},
        searchResult: {},
        dateSearchResult: {},
        product: {},
        hotel: {},
        checkout: {},
        clientCheck: {},
        order: {},
        //utils
        priceMode: {},
        language: 'de',
        showNavigation: true,
        //mobile
        showShareMobile: false,
        mobileHeaderTitle: '',
        //accessCode
        token: null,
        //vouchercode for login
        multiVoucherCode: null,
        multiVoucherProduct: null,
        //identity
        ident: '',
        theme: undefined,
        agreedToGDPR: false,
        layout: null,
        webP: false,
        favorites: [],
        utmSource: null
    };
};

export const getters = {
    isCombinedLayout(state) {
        return state.layout === 'combined';
    },
    isMainShop(state) {
        return (state.ident === 'null' || state.ident === 'www' || !state.ident) && !state.globalMeta.multivoucherShop;
    },
    isPartnershopWithPerNightPricing(state) {
        return ['mivo', 'falktravel', 'incent', 'femotion'].includes(state.ident);
    },
    isPartnerShopWithPackagePricing(state) {
        return ['kurzinurlaub'].includes(state.ident);
    },
    referredByNewsletter(state) {
        return (Array.isArray(state.utmSource) ? state.utmSource : [state.utmSource || '']).some(u =>
            ['animod newsletter', 'animod-newsletter', 'animod_newsletter'].includes(u.toLowerCase && u.toLowerCase())
        );
    }
};

export const mutations = {
    setGlobalMeta(state, globalMeta) {
        state.globalMeta = globalMeta;
    },
    setTranslations(state, translations) {
        state.translations = translations;
    },
    setHome(state, home) {
        state.home = home;
    },
    setCategory(state, category) {
        state.category = category;
    },
    addGridPage(state, { entity, grid }) {
        const foundGrid = state[entity].grids.find(g => g.id === grid.id);
        foundGrid.blocks = [...foundGrid.blocks, ...grid.blocks];
        foundGrid.page = grid.page;
        foundGrid.pageId = grid.pageId;
    },
    setSearchResult(state, searchResult) {
        state.searchResult = searchResult;
    },
    setDateSearchResult(state, dateSearchResult) {
        state.dateSearchResult = dateSearchResult;
    },
    setExactDateSearchResult(state, result) {
        state.dateSearchResult.exactMatches = result;
    },
    setDateRangeSearchResult(state, result) {
        state.dateSearchResult.dateRangeMatches = result;
    },
    setProduct(state, product) {
        state.product = product;
    },
    setHotel(state, hotel) {
        state.hotel = hotel;
    },
    setCheckout(state, checkout) {
        state.checkout = checkout;
    },
    setClientCheck(state, clientCheck) {
        state.clientCheck = clientCheck;
    },
    setOrder(state, order) {
        state.order = order;
    },
    setToken(state, token) {
        state.token = token;
    },
    setMultiVoucherCode(state, multiVoucherCode) {
        state.multiVoucherCode = multiVoucherCode;
    },
    setMultiVoucherProduct(state, multiVoucherProduct) {
        state.multiVoucherProduct = multiVoucherProduct;
    },
    setIdent(state, ident) {
        state.ident = ident;
    },
    setTheme(state, theme) {
        state.theme = theme;
    },
    setGDPRAgreement(state, value) {
        state.agreedToGDPR = value;
    },
    setPriceMode(state, priceMode) {
        state.priceMode = priceMode;
    },
    setLanguage(state, language) {
        state.language = language;
    },
    setShowNavigation(state, navigationVisible) {
        state.showNavigation = navigationVisible;
    },
    setMobileHeaderTitle(state, title) {
        state.mobileHeaderTitle = title;
    },
    setShowShare(state, showShare) {
        state.showShareMobile = showShare;
    },
    setLayout(state, layout) {
        state.layout = layout;
    },
    setWebP(state, value) {
        state.webP = value;
    },
    setFavorites(state, value) {
        state.favorites = value;
    },
    setUtmSource(state, value) {
        state.utmSource = value;
    }
};

export const actions = {
    async nuxtServerInit({ commit, dispatch, state }, nuxtContext) {
        const { req, isDev, query } = nuxtContext;
        //Gets called on every page. Not a desired behaviour
        //Init with a proper ident, all subsequent ajax calls will use ident header
        dispatch('setIdent', {
            host: req.headers.host,
            query,
            isDev
        });

        commit('setWebP', (req.headers.accept || '').includes('image/webp'));

        //Multivoucher surcharge preview case
        if (query.preview || this.$cookies.multiVoucherProduct) {
            dispatch('setMultiVoucherProduct', query.preview || this.$cookies.multiVoucherProduct);
        }

        function fixCode(code) {
            try {
                if (code && code.indexOf('x-pricemode') !== -1) {
                    return code.substring(0, code.indexOf('x-pricemode'));
                }
            } catch (e) {}
            return code;
        }

        //Init with a proper token if exists, all subsequent ajax calls will use token header
        //Should prevent from getting 401 response on the first call
        if (query.code || this.$cookies.accessToken) {
            dispatch('setToken', fixCode(query.code || this.$cookies.accessToken));
        }

        if (query.mvcode || this.$cookies.multiVoucherCode || query.code) {
            dispatch('setMultiVoucherCode', fixCode(query.mvcode || this.$cookies.multiVoucherCode || query.code));
        }

        commit('setUtmSource', query.utm_source);

        //Init with a proper price mode
        if (query['x-pricemode'] && priceModes[query['x-pricemode']]) {
            dispatch('togglePriceMode', priceModes[query['x-pricemode']] || {});
        } else if (identToPricemode[state.ident]) {
            dispatch('togglePriceMode', priceModes[identToPricemode[state.ident]] || {});
        } else {
            try {
                const priceMode = JSON.parse(this.$cookies.priceMode);
                commit('setPriceMode', priceMode || {});
            } catch (e) {
                commit('setPriceMode', {});
            }
        }

        commit('setGDPRAgreement', this.$cookies.gdprAgreement === 'true');

        const translations = await fetchResource(arguments[1], dispatch('getTranslations'));
        //if translations failed no sense to go further
        translations && (await fetchResource(arguments[1], dispatch('getGlobalMeta')));
    },
    async nuxtClientInit({ commit }) {
        // use to init application on a client side
        // client-side actions should not affect markup rendering
        commit('setFavorites', JSON.parse(localStorage.getItem('favorites')) || []);
    },
    async getGlobalMeta({ commit, dispatch }) {
        const { data: globalMeta } = await this.$axios.get('shop/cms/meta-data');

        //Uncomment to test globalMeta failures or access-code page
        /*const error = new Error('Server error');
        error.response = {
            status: 401,
            data: [{ authorizationViolationAuthorizationType: ErrorTypes.multiVoucherCode }]
        };
        throw error;*/

        commit('setGlobalMeta', globalMeta);
        //Coop header test comment
        /*commit('setGlobalMeta', {
            ...globalMeta,
            platformLogoUrl: "https://reiseschaetze.animod.de/assets-v4.2.5/themes/reiseschaetze/img/reiseschaetze_logo.png",
            headerVariant: 'cooperation'
        });*/
        return globalMeta;
    },
    async getTranslations({ commit, dispatch, state }) {
        //https://animod.atlassian.net/browse/ANI-1265
        const { data } = await this.$axios.get('translations/webshop', {
            headers: { 'x-language': state.language }
        });
        commit('setTranslations', data);
        return data;
    },
    async getHome({ commit, dispatch }) {
        const { data: home } = await this.$axios.get('shop/cms/home');
        commit('setHome', home);
    },
    async getCategory({ commit, dispatch }, categoryName) {
        const { data: category } = await this.$axios.get('/shop/cms/category/' + categoryName);
        commit('setCategory', category);
    },
    async getCategoryPreview({ commit, dispatch }, { category, hash }) {
        const { data: preview } = await this.$axios.get(`/shop/cms/preview/${category}/${hash}`);
        commitDelay(() => {
            commit('setCategory', preview);
        });
    },
    async postSearch({ commit, dispatch, state }, { params, mergeResults }) {
        const { data: searchResult } = await this.$axios.post('/shop/search', params);
        if (mergeResults) {
            const { products: newProducts, pageSize, page } = searchResult;
            commit('setSearchResult', {
                ...state.searchResult,
                products: [...state.searchResult.products, ...newProducts],
                page,
                pageSize
            });
        } else {
            commit('setSearchResult', searchResult);
        }
    },
    async postDateSearch({ commit, dispatch, state }, { params, mergeResults }) {
        const { data: dateSearchResult } = await this.$axios.post('/shop/date-search', params);
        if (mergeResults) {
            const { products: newProducts, pageSize, page } = dateSearchResult;
            commit('setDateSearchResult', {
                ...state.dateSearchResult,
                products: [...state.dateSearchResult.products, ...newProducts],
                page,
                pageSize
            });
        } else {
            commit('setDateSearchResult', dateSearchResult);
        }
    },
    async postDateSearchPartial({ commit, dispatch, state }, { params }) {
        const { data: partial } = await this.$axios.post('/shop/date-search-grid', params);
        if (params['searchMode'] === 'exactDates') {
            commit('setDateSearchResult', {
                ...state.dateSearchResult,
                exactMatches: partial
            });
        } else if (params['searchMode'] === 'checkinDate') {
            commit('setDateSearchResult', {
                ...state.dateSearchResult,
                dateRangeMatches: partial
            });
        } else if (params['searchMode'] === 'all') {
            commit('setDateSearchResult', {
                ...state.dateSearchResult,
                allMatches: partial
            });
        } else {
            commit('setDateSearchResult', {
                ...state.dateSearchResult,
                defaultResult: partial
            });
        }
    },
    async postMapSearch({ commit, dispatch }, payload) {
        const { data: searchResult } = await this.$axios.post('/shop/hotelmap', payload);
        commit('setSearchResult', searchResult);
    },
    async getProduct({ commit, dispatch }, productId) {
        const { data: product } = await this.$axios.get('/shop/product-details/' + productId);
        commitDelay(() => {
            commit('setProduct', product);
        });
    },
    async getPreview({ commit, dispatch }, { productId, checksum }) {
        const { data: preview } = await this.$axios.get(`/shop/product-preview/${productId}/${checksum}`);
        commitDelay(() => {
            commit('setProduct', preview);
        });
    },
    async getHotel({ commit, dispatch }, hotelId) {
        const { data: hotel } = await this.$axios.get('/shop/hotel-details/' + hotelId);
        commit('setHotel', hotel);
    },
    async getTopHotels({ dispatch }, { start, stop }) {
        const { data: topHotels } = await this.$axios.get(`/tophotels/${start}/${stop}`);
        return topHotels;
    },
    async getVoucher({ commit, dispatch }, voucherId) {
        const { data: voucher } = await this.$axios.get('/shop/voucher-details/' + voucherId);
        commitDelay(() => {
            commit('setProduct', voucher);
        });
    },
    async getCheckout({ commit, dispatch }, query) {
        commit('setCheckout', {});
        const { data: checkout } = await this.$axios.get('/shop/checkout', { params: query });
        commit('setCheckout', checkout);
    },
    async getClientCheck({ commit, dispatch }, query) {
        commit('setClientCheck', {});
        const { data: checkState } = await this.$axios.get('/shop/client-check');
        commit('setClientCheck', checkState);
    },
    processOrder({ commit }, order) {
        /**
         * since placing order might be rejected with our Custom error, action should just return Promise
         * to make error processing inside component possible
         */
        return new Promise((resolve, reject) => {
            this.$axios.post('/shop/process-order', order).then(({ data }) => {
                resolve(data);
            }, reject);
        });
    },
    async getOrder({ commit, dispatch }, { orderId, orderHash }) {
        commit('setOrder', {});
        const { data: order } = await this.$axios.get(`/shop/order-details/${orderId}/${orderHash}`);
        commit('setOrder', order);
    },
    setToken({ commit }, token) {
        this.$cookies.accessToken = token;
        commit('setToken', token);
    },
    setMultiVoucherCode({ commit }, multiVoucherCode) {
        this.$cookies.multiVoucherCode = multiVoucherCode;
        commit('setMultiVoucherCode', multiVoucherCode);
    },
    setMultiVoucherProduct({ commit }, previewProduct) {
        this.$cookies.multiVoucherProduct = previewProduct;
        commit('setMultiVoucherProduct', previewProduct);
    },
    setIdent({ commit }, { host, query, isDev: developmentMode }) {
        const subdomain = host.split('.')[0];
        const dashedSubdomainParts = subdomain.split('-');
        const isDeployedOnDevOrStage =
            dashedSubdomainParts.length > 1 &&
            (dashedSubdomainParts[0] === 'dev' ||
                dashedSubdomainParts[0] === 'stage' ||
                dashedSubdomainParts[0] === 'test');
        const isDev = ~subdomain.indexOf('localhost') || ~subdomain.indexOf('192') || developmentMode;
        const forceIdent = query['x-ident'] || this.$cookies.devIdent;
        const themes = process.env.NUXT_ENV_THEMES; //gets populated via theme.config.js to the env variable during build

        // ident definition
        let ident;
        if ((isDeployedOnDevOrStage && forceIdent) || isDev) {
            ident = forceIdent;
        } else if (domainToIdent[host] !== undefined) {
            ident = domainToIdent[host];
        } else if (isDeployedOnDevOrStage) {
            ident = dashedSubdomainParts[dashedSubdomainParts.length - 1];
        } else {
            ident = subdomain;
        }

        // theme definition
        let theme;
        if (~themes.indexOf(query['x-theme']) || identToTheme[ident] || ~themes.indexOf(ident)) {
            theme = query['x-theme'] || identToTheme[ident] || ident;
            commit('setTheme', theme);
        }

        if (isDev) {
            this.$cookies.devIdent = ident;
        }

        commit('setIdent', ident);
    },
    async deleteAccount({ commit }, hash) {
        const { data: status } = await this.$axios.post(`/shop/confirm-account-deletion/${hash}`);
        return status;
    },
    /*submitCancellationForm({commit}, payload) {
        /!**
         * Promise to process errors manually
         *!/
        return new Promise((resolve, reject) => {
            this.$axios.post('/shop/process-order', order).then(({data}) => {
                commit('setOrder', data);
                resolve(data);
            }, reject);
        });
    },*/
    togglePriceMode({ commit }, priceMode) {
        this.$cookies.priceMode = JSON.stringify(priceMode);
        commit('setPriceMode', priceMode);
    },
    agreeToGDPR({ commit }) {
        this.$cookies.gdprAgreement = true;
        commit('setGDPRAgreement', true);
    },
    updateGrid({ commit, state }, grid) {
        const findById = g => g.id === grid.id;
        if (state.home.grids && state.home.grids.find(findById)) {
            commit('addGridPage', { entity: 'home', grid });
        } else if (state.category.grids && state.category.grids.find(findById)) {
            commit('addGridPage', { entity: 'category', grid });
        }
    },
    setFavorites({ commit }, favorites) {
        commit('setFavorites', favorites);
        if (process.client) {
            localStorage.setItem('favorites', JSON.stringify(favorites));
        }
    },
    //For technical dev purposes
    async _updateTranslation({ commit, state }, { key, value }) {
        try {
            let boToken;
            if (process.client) {
                boToken = localStorage.getItem('x-auth-token');
            }

            const token = state.token;
            const multiVoucherCode = state.multiVoucherCode;
            const multiVoucherProduct = state.multiVoucherProduct;
            const ident = state.ident;
            const apiKey = this.$axios.defaults.headers.common['x-api-key'];

            commit('setToken', null);
            commit('setMultiVoucherCode', null);
            commit('setMultiVoucherProduct', null);
            commit('setIdent', null);
            delete this.$axios.defaults.headers.common['x-api-key'];

            await this.$axios.put(
                `translations/webshop/${state.language}/${key}`,
                {
                    value
                },
                { headers: { 'x-auth-token': boToken } }
            );

            commit('setToken', token);
            commit('setMultiVoucherCode', multiVoucherCode);
            commit('setMultiVoucherProduct', multiVoucherProduct);
            commit('setIdent', ident);
            this.$axios.defaults.headers.common['x-api-key'] = apiKey;
        } catch (e) {}
    }
};
