<template functional>
    <h2 class="grid-title">
        <slot />
    </h2>
</template>

<script>
export default {
    name: 'GridTitle'
};
</script>

<style lang="scss">
.grid-title {
    color: var(--grid-title-color);
    font-size: 20px;
    font-weight: bold;
}
</style>
