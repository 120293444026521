//taken from  https://github.com/artsy/detect-responsive-traits/blob/master/src/index.ts

/**
 * Notes:
 * - Mobile Safari does not include device identifier. We can try to limit somewhat by looking at OS supported versions.
 * - The Facebook app does include device identifiers when using its builtin webview, so prefer that.
 *
 * Links to gather data from:
 * - https://artsy.looker.com/sql/z3zhf9crpbxqcw
 * - https://artsy.looker.com/sql/ybq76vhnzk9dgs
 * - https://www.theiphonewiki.com/wiki/Models
 * - https://en.wikipedia.org/wiki/List_of_iOS_devices
 * - http://vizdevices.yesviz.com/devices.php
 * - http://www.tera-wurfl.com/explore/index.php
 * - https://www.mydevice.io
 * - http://responsivechecker.net/responsive
 * - https://medium.com/@hacknicity/how-ipad-apps-adapt-to-the-new-11-and-12-9-ipads-pro-cabd1c0e5f20
 */

/**
 * The responsive traits of a device (which can span multiple models) and how to recognize it from a user-agent.
 */

import deviceList from '@/utils/devices';
//import config from '../../nuxt.config';

/**
 * @class
 */
class Device {
    /**
     * @constructor
     * @param {String} description -  A human readable description of the device(s)
     * @param {RegExp} userAgent - A regular-expression that matches the client’s user-agent.
     * @param {Number} minWidth -The minimum display width of the device, which is either the total width of the device’s viewport when held in its
     * @param {Number} maxWidth - The maximum display width of the device, which is the height of the device’s viewport when held in its portrait orientation.
     * @param {Boolean} resizable - In case resizing is supported, the device should be considered as being able to display at any size between
                * `minWidth` and `maxWidth`. In case resizing is not supported, `minWidth` and `maxWidth` should be considered as the
                * only two possible width values.
     * @param {Number} pixelRatio - The number of pixels along an axis that make up 1 point.
     * @param {Boolean} touch - Whether or not the device is a touch-screen based device.
     */
    constructor ({
        description,
        userAgent,
        minWidth,
        maxWidth,
        resizable,
        pixelRatio,
        touch
    }) {
        Object.assign(this, {
            description,
            userAgent,
            minWidth,
            maxWidth,
            resizable,
            pixelRatio,
            touch
        })
    }

    checkBreakpointCoverage(breakpointPixels) {
        this.hasCombinedLayout = this.maxWidth >= breakpointPixels;
    }
}

const devices = deviceList.map(device => new Device(device));

// Mobile Safari does not include model info, so default to most inclusive unions of available versions
devices.push(new Device({
    description: "iPhone",
    userAgent: /iPhone;/,
    resizable: false,
    touch: true,
    ...deviceUnion(devices.filter(device => device.userAgent.toString().includes("iPhone"))),
}));
devices.push(new Device({
    description: "iPod touch",
    userAgent: /iPod touch;/,
    resizable: false,
    touch: true,
    ...deviceUnion(devices.filter(device => device.userAgent.toString().includes("iPod"))),
}));
devices.push(new Device({
    description: "iPad",
    userAgent: /iPad;/,
    resizable: true,
    touch: true,
    ...deviceUnion(devices.filter(device => device.userAgent.toString().includes("iPad"))),
}));


function deviceUnion(devices) {
    return devices.reduce(
        (acc, device) => ({
            ...acc,
            minWidth: device.minWidth < acc.minWidth ? device.minWidth : acc.minWidth,
            maxWidth: device.maxWidth > acc.maxWidth ? device.maxWidth : acc.maxWidth,
            pixelRatio: device.pixelRatio > acc.pixelRatio ? device.pixelRatio : acc.pixelRatio,
        }),
        { minWidth: 999999, maxWidth: 0, pixelRatio: 0 }
    );
}

export default function getDeviceTrait (userAgent, mediaBreakpoint) {
    const device = devices.find(device => device.userAgent.test(userAgent));
    if (device) {
        device.checkBreakpointCoverage(mediaBreakpoint)
    }
    return device;
}
