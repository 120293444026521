<template>
    <div class="gift-box-banner">
        <div class="banner-content">
            <picture>
                <source srcset="@/assets/webp/banners/AnimodGiftbox.webp" type="image/webp" />
                <img src="@/assets/banners/Animod Giftbox.png" class="banner-image" alt="Giftbox image" />
            </picture>
            <div class="banner-text">{{ $t('Banner.giftBox.content') }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GiftBoxBanner'
};
</script>

<style scoped lang="scss">
@use 'sass:map';
@use '@/styles/variables';
@use '@/styles/bootstrap/breakpoints';

.gift-box-banner {
    z-index: map.get(variables.$z-indices, 'giftbox-banner');
    background-color: hsla(0, 0%, 92%, 0.5);
    padding: 16px;
    position: absolute;
    overflow: visible;

    .banner-content {
        background: hsl(345, 100%, 25%);
        color: hsl(0, 0%, 100%);
        line-height: 32px;
        font-size: 24px;
        font-weight: 700;
        box-shadow: 0 7px 18px 0 hsla(345, 84%, 25%, 0.2);
        padding: 16px;
    }

    @include breakpoints.media-breakpoint-up(lg) {
        width: 420px;
        right: 32px;
        top: 60px;

        .banner-image {
            position: absolute;
            top: -31px;
            left: -50px;
        }

        .banner-text {
            padding-left: 100px;
            text-align: right;
        }
    }

    @include breakpoints.media-breakpoint-up(xl) {
        top: 100px;
        left: 40px;
        width: 325px;
        text-align: center;

        .banner-image {
            position: relative;
            left: auto;
            margin-top: -100px;
        }

        .banner-text {
            padding-left: 0;
            text-align: center;
            margin-top: -50px;
        }
    }
}
</style>
