function createRipple(event) {
    const target = event.currentTarget;
    const btnRect = target.getBoundingClientRect();
    const circle = document.createElement('span');
    const diameter = Math.max(target.clientWidth, target.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - (btnRect.left + radius)}px`;
    circle.style.top = `${event.clientY - (btnRect.top + radius)}px`;
    circle.classList.add('ripple');

    //checking for any existing ripples that might be leftover from previous clicks,
    // and removing them before executing the next one.
    const ripple = target.getElementsByClassName('ripple')[0];

    if (ripple) {
        ripple.remove();
    }

    target.appendChild(circle);

    Promise.all(
        circle.getAnimations().map(function (animation) {
            return animation.finished;
        })
    ).then(
        function () {
            return circle.remove();
        },
        function () {
            // Sit and relax
        }
    );
}

export default {
    /**
     *
     * @param {HTMLElement} element
     * @param binding
     */
    bind(element, binding) {
        element.classList.add('rippleable');
        element.addEventListener('click', createRipple);
    },
    /**
     *
     * @param {HTMLElement} element
     */
    unbind(element) {
        element.removeEventListener('click', createRipple);
    }
};
