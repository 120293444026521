/**
 * Safely gets multiple levels deep values
 * @function
 * @param {Object} obj - Object to get nested values from
 * @param {String} dottedKey - dot separated key names. For arrays: key.[index].key
 * @returns {T} - nested value
 */
const getSafe = function (obj, dottedKey) {
    return dottedKey.split('.').reduce((final, current, index, keys) => {
        const lastIteration = index === keys.length - 1;
        const isArray = /^\[(\d+)\]$/.test(current);
        if (isArray) {
            const propExists = final[+current.match(/\d+/)[0]] != null; // failed on 0, needs to be exactly nullish
            return propExists ? final[+current.match(/\d+/)[0]] : lastIteration ? null : [];
        }
        const propExists = final[current] != null; // failed on 0, needs to be exactly nullish
        return propExists ? final[current] : lastIteration ? null : {};
    }, obj);
};

/**
 * Safely sets nested properties of an object
 * @function
 * @param {Object} obj - Object to set nested values to
 * @param {String} dottedKey - dot separated key names
 * @param {T} value - the value to set
 */
const setSafe = function (obj, dottedKey, value) {
    dottedKey.split('.').reduce((final, current, index, keys) => {
        if (index === keys.length - 1) {
            this.set(final, current, value);
            //final[current] = value;
            return final;
        }
        return final[current] || this.set(final, current, {});
    }, obj);
};

/**
 * Flag that indicated if project is run in development
 * @constant
 * @type {Boolean}
 */
const isDev = process.env.NODE_ENV === 'development';

const isEmptyObject = function (obj = {}) {
    return !Object.keys(obj).length;
};

const searchObjectForValue = (results => {
    return function search(obj, value, parentKey = '') {
        console.assert(value !== undefined, 'You must specify a value');

        const isObject = typeof obj === 'object' && !!obj; //typeof null gives "object"
        const parentKeys = parentKey.split('.');

        if (!isObject) return;

        for (const [key, val] of Object.entries(obj)) {
            const isRecursion = parentKeys[parentKeys.length - 1] === key;

            if (isRecursion) {
                return;
            }

            const currentKey = [parentKey, key].filter(Boolean).join('.');

            if (typeof val === 'object') {
                search(val, value, currentKey);
            } else if (val === value) {
                results[currentKey] = val;
            }
        }

        const isTopFunctionCall = !parentKey;
        if (isTopFunctionCall) {
            console.table(results);
        }
    };
})({});

const sanitizeHTMLComment = htmlComment => {
    if (typeof htmlComment === 'string' && htmlComment.trim().startsWith('<!--')) {
        return `Commented out: ${htmlComment.replace('<!--', '').replace('-->', '')}`;
    }
    return htmlComment;
};

const getRandomInt = (min = 0, max = 1) => Math.round(Math.random() * (max - min) + min);

/**
 * $ sign is added by default by convention
 */
export default {
    getSafe,
    setSafe,
    isDev,
    isEmptyObject,
    searchObjectForValue,
    sanitizeHTMLComment,
    getRandomInt
};
