import Vue from 'vue';

/**
 * @class CookieManager
 */
class CookieManager {
    /**
     *
     * @param vv
     * @param mc_eid
     * @param ac
     * @param st
     * @param xop
     * @param emp_id
     * @param req
     * @param res
     * @param setHeader
     * @param $config
     */
    constructor({ vv, mc_eid, ac, st, xop, emp_id }, req, res, setHeader, $config) {
        this.req = req;
        this.res = res;
        this.setHeader = setHeader;
        this.$config = $config;

        vv && (this.vv = vv);

        ac && (this.ac = ac);

        st && (this.st = st);

        xop && (this.xop = xop);

        emp_id && (this.emp_id = emp_id);

        if (process.server) {
            if (!req.headers.dnt) {
                this.mc_eid = mc_eid;
            }
        }

        if (process.client) {
            if (!navigator.doNotTrack) {
                this.mc_eid = mc_eid;
            }
        }

        //this.injectCookieManagerIntoVueInstance();
    }

    /**
     * Sets the cookie
     * @param {String} name - cookie name to set
     * @param {String} [value] - cookie value to set
     * @param {Number|String} [age] - cookie age, fallbacks to 31536000 = year in seconds, can be set as "session"
     */
    setCookie(name, value, age = 31536000) {
        let cookie;

        if (!value) {
            cookie = `${name}='';max-age=-1;path=/;`;
        } else if (age === 'session') {
            cookie = `${name}=${value}; path=/;`;
        } else {
            cookie = `${name}=${value}; max-age=${age};path=/;`;
        }

        if (process.server) {
            const previouslySetCookies = this.res.getHeader('set-cookie');
            const cookiesList = [...(previouslySetCookies || []), cookie];
            this.res.setHeader('Set-Cookie', cookiesList);
        } else {
            document.cookie = cookie;
        }
    }

    /**
     * Gets the cookie
     * @param {String} cookieName
     * @returns {string}
     */
    getCookie(cookieName) {
        let name = cookieName + '=';
        const isServer = process.server;
        let decodedCookies;
        if (isServer) {
            decodedCookies = this.req.headers.cookie;
        } else {
            decodedCookies = decodeURIComponent(document.cookie);
        }
        if (decodedCookies) {
            const ca = decodedCookies.split(';');
            for (let i = 0; i < ca.length; i++) {
                const c = ca[i].trim();
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length);
                }
            }
        }
        return null;
    }

    /**
     *
     * @param name
     */
    deleteCookie(name) {
        this.setCookie(name, '');
    }

    /**
     * valuevoucher-code (or discount-code) that is automatically filled in on the checkout-page
     * @returns {string}
     */
    get vv() {
        return this.getCookie('vv');
    }
    set vv(value) {
        this.setCookie('vv', value, 'session');
    }

    /**
     * This parameter contains the mailchimp-email-id and is included in deep-links within the newsletter.
     * @returns {string}
     */
    get mc_eid() {
        return this.getCookie('mc_eid');
    }
    set mc_eid(value) {
        this.setCookie('mc_eid', value);
    }

    /**
     * The ac-Parameter is used for arbitrary campaign infos
     * @returns {string}
     */
    get ac() {
        return this.getCookie('ac');
    }
    set ac(value) {
        this.setCookie('ac', value);
    }

    /**
     * Single transaction cookie
     * @returns {string}
     */
    get st() {
        return this.getCookie('st');
    }
    set st(value) {
        this.setCookie('st', value);
    }

    /**
     * The xop-Parameter is used to override the platform that an order is attributed to
     * @returns {string}
     */
    get xop() {
        return this.getCookie('xop');
    }
    set xop(value) {
        this.setCookie('xop', value, 30 * 24 * 60 * 60); //30 days in seconds
    }

    /**
     * The emp_id-Parameter is used to track the employee-ID of the Animod service staff
     * @returns {string}
     */
    get emp_id() {
        return this.getCookie('emp_id');
    }
    set emp_id(value) {
        this.setCookie('emp_id', value);
    }

    /**
     * User preferred price mode
     * @returns {string}
     */
    get priceMode() {
        return this.getCookie('priceMode');
    }
    set priceMode(value) {
        this.setCookie('priceMode', value);
    }

    /**
     * cookies_notice - flag for displaying bottom notice about cookies usage
     * @returns {string}
     */
    get cookies_notice() {
        return this.getCookie('animod-c');
    }
    set cookies_notice(value) {
        this.setCookie('animod-c', value);
    }

    /**
     * Subscriptions map stores product ids which the user has subscribed to
     * @returns {string}
     */
    get subscriptions() {
        return this.getCookie('subscriptions');
    }
    set subscriptions(value) {
        this.setCookie('subscriptions', value);
    }

    /**
     * Newsletter
     * @returns {string}
     */
    get showNewsletterModal() {
        return this.getCookie('showNewsletter');
    }
    set showNewsletterModal(value) {
        this.setCookie('showNewsletter', value, 60 * 60 * 24 * 90);
    }

    /**
     * GDPR agreement (map data, youtube snippets, etc)
     * @returns {string}
     */
    get gdprAgreement() {
        return this.getCookie('animod-gdpr');
    }
    set gdprAgreement(value) {
        this.setCookie('animod-gdpr', value, 'session');
    }

    /**
     * Access token
     * @returns {string}
     */
    get accessToken() {
        return this.getCookie(this.$config.tokenKeyName);
    }
    set accessToken(value) {
        this.setCookie(this.$config.tokenKeyName, value);
    }

    /**
     * Multi voucher code cookie is used for the surcharge
     * @returns {string}
     */
    get multiVoucherCode() {
        return this.getCookie(this.$config.voucherKeyName);
    }
    set multiVoucherCode(value) {
        this.setCookie(this.$config.voucherKeyName, value);
    }

    /**
     * Multi voucher preview product, surcharge case
     * @returns {string}
     */
    get multiVoucherProduct() {
        return this.getCookie(this.$config.voucherProductKeyName);
    }
    set multiVoucherProduct(value) {
        this.setCookie(this.$config.voucherProductKeyName, value);
    }

    /**
     * Dev mode ident persistence.
     * Fallbacks query string parameter that will be lost upon navigation and page refresh
     * @returns {string}
     */
    get devIdent() {
        return this.getCookie('x-ident');
    }
    set devIdent(value) {
        this.setCookie('x-ident', value, 'session');
    }

    /**
     * Injects manager to the global vue instance
     */
    injectCookieManagerIntoVueInstance() {
        Vue.prototype.$cookies = this;
    }

    /**
     * Fixes:
     * Chromium now requires samesite and secure attrs to be set. Otherwise iframe shops won't be able to read cookies
     * @link https://www.chromestatus.com/feature/4672634709082112
     * @link https://tools.ietf.org/html/draft-ietf-httpbis-cookie-same-site-00
     * !IMPORTANT! Required for all cookies being set inside out iframe layouts, same applies to 4rd party plugins like ppplus.
     */
    fixIframeCookies() {
        if (process.client && process.env.NODE_ENV === 'production') {
            // cookie proxy
            const cookieDesc =
                Object.getOwnPropertyDescriptor(Document.prototype, 'cookie') ||
                Object.getOwnPropertyDescriptor(HTMLDocument.prototype, 'cookie');
            if (cookieDesc && cookieDesc.configurable) {
                Object.defineProperty(document, 'cookie', {
                    get: function () {
                        return cookieDesc.get.call(document);
                    },
                    set: function (val) {
                        const valueLower = val.toLowerCase();
                        if (!valueLower.endsWith(';')) {
                            val += ';';
                        }
                        if (!valueLower.includes('secure')) {
                            val += ' secure;';
                        }
                        if (!valueLower.includes('samesite')) {
                            val += ' samesite=None;';
                        }
                        cookieDesc.set.call(document, val);
                    }
                });
            }
        }
    }

    /**
     * @returns { boolean }
     */
    supported() {
        try {
            this.setCookie('test', '1');
            return this.getCookie('test') === '1';
        } catch (e) {
            return false;
        }
    }
}

export default CookieManager;
