import Block from './Block';

/**
 * @class
 * @extends Block
 */
export default class ContentBlock extends Block {
    /**
     * @param {boolean} dimmed
     * @param {...object} rest
     */
    constructor({ dimmed, ...rest }) {
        super(rest);
        Object.assign(this, {
            dimmed
        });
    }
}
