import priceModes from '@/models/priceModes';
import ProductPriceInformation from '@/models/pre-ts/product/ProductPriceInformation';
import formatPriceFilter from '@/filters/formatPrice';

export default {
    props: {
        price: {
            required: true,
            type: [Object, ProductPriceInformation]
        }
    },
    computed: {
        [priceModes.package.property]() {
            return this.regularPriceValues.minimumPrice.price;
        },
        [priceModes.perPerson.property]() {
            return this.$getSafe(this.regularPriceValues, `${priceModes.perPerson.property}.perPersonPrice`);
        },
        [priceModes.perNight.property]() {
            return this.$getSafe(this.regularPriceValues, `${priceModes.perNight.property}.perPersonPerNightPrice`);
        },
        priceModeProperty() {
            //if specified, preferred mode takes precedence over the global price mode
            const specifiedPriceModeProperty = this.preferredMode || this.$store.state.priceMode.property;
            if (this[specifiedPriceModeProperty]) {
                return specifiedPriceModeProperty;
            } else {
                //here iterating over all available price modes to find the first existing price value
                let property;
                for (let priceMode of priceModes) {
                    if (this[priceMode.property] != null) {
                        property = priceMode.property;
                    }
                }
                return property;
            }
        },
        isDiscountVisible() {
            return this.$store.state.globalMeta.discountVisible;
        },
        discountPercentage() {
            return this.modePrices.discountPercentage;
        },
        regularPriceValues() {
            return this.price.regularPriceValues || {};
        },
        modePrices() {
            return this.regularPriceValues[this.priceModeProperty] || {};
        },
        firstAvailablePrice() {
            if (typeof this[this.priceModeProperty] === 'number') {
                return formatPriceFilter(this[this.priceModeProperty], this.hideCents);
            }
            return this[this.priceModeProperty];
        }
    }
};
