<template>
    <div class="grid-load-more">
        <button
            v-if="!inProgress"
            @click="loadMoreOffers"
            role="button"
            type="button"
            class="more-offers-btn btn btn-info btn-lg"
        >
            {{ $t('Grid.loadMore') }}
        </button>
        <IconSvg v-else filepath="spinner.svg" class="more-offers-spinner text-primary" />
    </div>
</template>

<script>
export default {
    name: 'GridLoadMore',
    props: {
        /**
         * Flag that indicated when to hide spinner
         */
        inProgress: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        loadMoreOffers() {
            /**
             * @event loadMore
             * @type event
             */
            this.$emit('loadMore');
        }
    }
};
</script>

<style scoped lang="scss">
@use 'sass:map';
@use '@/styles/variables';
@use '@/styles/bootstrap/variables' as bsvars;
@use '@/styles/bootstrap/breakpoints' as breakpoints;

.grid-load-more {
    height: 56px;
    margin-top: variables.$grid-gutter-width - map-get(variables.$grid, 'tile-shadow-blur');
    display: flex;
    justify-content: center;
    align-items: center;

    .more-offers-btn {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 1px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    }

    .more-offers-spinner {
        font-size: 40px;
        color: bsvars.$info;
        text-align: center;
    }

    @include breakpoints.media-breakpoint-down(variables.$mobile-breakpoint-max) {
        margin-top: 0;
        width: var(--grid-tile-width-mobile);
        height: var(--grid-tile-height-mobile);

        .more-offers-btn {
            white-space: nowrap;
            font-size: 14px;
            padding: 14px;
        }
    }
}
</style>
