<template>
    <img
        :src="lazySrc"
        :srcset="lazySrcSet"
        :alt="alt"
        class="image"
        :class="{ loading, loaded, animate }"
        ref="image"
        :width="width"
        :height="height"
    />
</template>

<script>
export default {
    name: 'ImageLazy',
    data() {
        return {
            lazySrc: this.src && require('@/assets/transparent.png'),
            lazySrcSet: this.srcset && require('@/assets/transparent.png'),
            loaded: false,
            loading: false
            //disabled native support: required explicit image dimensions, hardly animated, worse mobile support
            //supportsNativeLazy: process.client && 'loading' in HTMLImageElement.prototype,
        };
    },
    props: {
        src: String,
        srcset: String,
        alt: {
            type: String,
            default() {
                return this.$t('ImageLazy.altDefault');
            }
        },
        canLoad: {
            type: Boolean,
            default: true
        },
        forceLoad: Boolean,
        animate: {
            type: Boolean,
            default: true
        },
        width: {
            type: [Number, String],
            default: 100
        },
        height: {
            type: [Number, String],
            default: 100
        }
    },
    methods: {
        loadImage() {
            this.loading = true;

            const image = new Image();

            image.onload = async () => {
                this.nativeLoad(); //Important! Without caching (e.g. network panel in dev tools with disabled cache) will trigger a second image request.

                //Timeout magically fixes the fadeIn animation
                setTimeout(() => {
                    this.loaded = true;
                    this.loading = false;
                }, 200);
            };

            if (this.srcset) {
                image.srcset = this.srcset;
            } else if (this.src) {
                image.src = this.src;
            }
        },
        nativeLoad() {
            this.srcset ? (this.lazySrcSet = this.srcset) : (this.lazySrc = this.src);
        },
        startObserving() {
            /*
                    Removed native lazy-load support check since it doesn't work correctly on the mobile devices
                    apparently. Maybe we can check the native lazy load after some several updates
                    Desktop native lazy load worked fine
                 */
            //if (!this.supportsNativeLazy && this.$intersectionObserver) {
            if (this.$intersectionObserver) {
                this.$intersectionObserver.add(this.$el, this.loadImage);
            } else {
                this.loadImage();
            }
        },
        endObserving() {
            if (this.$intersectionObserver) {
                this.$intersectionObserver.unobserve(this.$el);
            }
        }
    },
    watch: {
        canLoad(canLoad) {
            if (canLoad) {
                this.endObserving();
                this.startObserving();
            }
        },
        src(src) {
            if (src !== this.lazySrc) {
                this.endObserving();
                this.startObserving();
            }
        },
        srcset(srcset) {
            if (srcset !== this.lazySrcSet) {
                this.endObserving();
                this.startObserving();
            }
        }
    },
    created() {
        if (this.forceLoad) {
            this.loaded = true;
            this.nativeLoad();
        }
    },
    mounted() {
        if (this.canLoad && !this.forceLoad) {
            this.startObserving();
        }
    },
    beforeDestroy() {
        this.endObserving();
    }
};
</script>

<style scoped lang="scss">
img.image {
    object-position: center;
    object-fit: cover;
    width: 100%;
    height: 100%;
    background-color: var(--body-bg);

    &.animate {
        transition: opacity 0.3s linear;
        opacity: 0;

        &.loaded {
            opacity: 1;
        }
    }

    &.loading {
    }
}
</style>
