<template>
    <component :is="blockType" :block="block" :style="blockStyle" class="grid-block" ref="gridItem" :highest="highest">
    </component>
</template>

<script>
import GridTile from '@/components/grid/GridTile';

export default {
    name: 'GridItem',
    components: {
        GridTile,
        KIUGridTile: () =>
            import(/* webpackChunkName: "EdekaGroupGrid" */ '@/themes/kurzinurlaub/components/KIUGridTile.vue'),
        PlainGridTile: () => import(/* webpackChunkName: "EdekaGroupGrid" */ '@/components/grid/PlainGridTile.vue'),
        EdekaLegacyTile: () =>
            import(/* webpackChunkName: "EdekaGrid" */ '@/themes/edeka/components/grid/EdekaLegacyTile.vue'),
        MivoGridTile: () => import(/* webpackChunkName: "EdekaGrid" */ '@/themes/mivo/components/grid/MivoGridTile.vue')
    },
    props: {
        block: Object,
        allowResizing: Boolean,
        highest: Boolean
    },
    computed: {
        blockStyle() {
            if (this.allowResizing) {
                return {
                    'grid-column': `span ${this.block.columns}`,
                    'grid-row': `span ${this.block.rows}`
                };
            }
            return '';
        },
        ident() {
            return this.$store.state.ident || '';
        },
        blockType() {
            switch (this.ident) {
                case 'kurzinurlaub':
                    return 'KIUGridTile';
                case 'netto-discount':
                case 'nettovorteilswelt':
                case 'marktkaufreisen':
                case 'edeka-suedwest':
                    return 'PlainGridTile';
                case 'mivo':
                    return 'MivoGridTile';
                case 'edeka':
                    return 'EdekaLegacyTile';
                default:
                    return 'GridTile';
            }
        }
    }
};
</script>

<style scoped lang="scss">
@use 'sass:map';
@use '@/styles/variables';
@use '@/styles/bootstrap/breakpoints' as breakpoints;

.grid-block {
    text-decoration: none;

    @include breakpoints.media-breakpoint-only(xl) {
        min-height: var(--grid-tile-height-xl);
    }

    @include breakpoints.media-breakpoint-only(lg) {
        min-height: var(--grid-tile-height-lg);
    }

    @include breakpoints.media-breakpoint-down(variables.$mobile-breakpoint-max) {
        width: var(--grid-tile-width-mobile);
        margin-bottom: map-get(variables.$grid, 'tile-shadow-blur');
        height: var(--grid-tile-height-mobile);
    }

    @include variables.ie {
        -ms-flex: 0 0 calc(33% - 2 * #{variables.$grid-padding});
        flex: 0 0 calc(33% - 2 * #{variables.$grid-padding});
        width: calc(33% - 2 * #{variables.$grid-padding});
        margin: variables.$grid-padding;
        min-width: 300px;

        ::v-deep {
            .product-image,
            .content-image {
                top: 0;
                left: 0;
            }

            .banners {
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
                justify-content: flex-start !important;

                .block-banner {
                    margin-left: auto !important;
                }
            }
        }
    }
}
</style>
