<template>
    <Link
        class="grid-tile"
        :link="block.link"
        v-ripple
        :class="{ 'grid-tile--content': hasMiddleTitle, 'grid-tile--dimmed': block.dimmed }"
    >
        <div v-if="showHeader" class="grid-tile__header">
            <div class="grid-tile__title text-truncate" :title="title" itemprop="name">
                {{ title }}
            </div>

            <div class="grid-tile__subtitle">
                <Classification
                    v-if="block.hotelClassification"
                    :hotelClassification="block.hotelClassification"
                    inline
                />

                <span v-else-if="block.subtitle">{{ block.subtitle }}</span>

                <div v-if="showDuration" v-html="duration" class="grid-tile__duration ml-auto"></div>
            </div>
        </div>

        <div class="grid-tile__media-wrapper">
            <ImageFluid
                :src="block.imageUrl"
                :alt="block.text"
                class="grid-tile__media-image"
                :eager="highest"
                width="750"
                height="550"
            />

            <div v-if="block.soldOut" class="grid-tile__sold-out">
                <div class="grid-tile__sold-out-text">{{ $t('ProductDetails.productSelect.soldOut') }}</div>
            </div>

            <div class="grid-tile__media-top">
                <div class="grid-tile__media-top-left">
                    <CountdownTimer
                        v-if="block.availableUntil"
                        :available-until="block.availableUntil"
                        class="grid-tile__countdown-timer"
                    />

                    <div v-if="banner" class="grid-tile__banner grid-tile__media-element" :style="bannerStyle">
                        {{ banner }}
                    </div>
                </div>

                <!--TODO: take block.favorite when ready on backend-->
                <Favorite v-if="block.id" :product="block" class="grid-tile__favorite grid-tile__media-element" />
            </div>

            <div class="grid-tile__media-bottom">
                <!--gradient bottom-->
                <Price
                    v-if="productPrice"
                    :price="block.price"
                    :preferred-mode="
                        $store.state.ident === '2fuer1' || $store.state.ident === 'douglas'
                            ? 'minimumPerPersonPrice'
                            : undefined
                    "
                    small-prefix
                    class="grid-tile__price grid-tile__media-element"
                />

                <IconSvg
                    v-if="block.directBookingAvailable"
                    filepath="calendar.svg"
                    class="grid-tile__direct-booking grid-tile__media-element"
                />
            </div>
        </div>

        <div v-if="block.text" class="grid-tile__text" :title="block.text">
            <span class="grid-tile__clamp">{{ block.text }}</span>
        </div>
    </Link>
</template>

<script>
import ImageLazy from '@/components/ImageLazy';
import Product from '@/models/pre-ts/product/Product';
import ContentBlock from '@/models/pre-ts/cms/ContentBlock';
import ValueVoucher from '@/models/pre-ts/product/ValueVoucher';
import Price from '@/components/PriceNew';
import DiscountBadge from '@/components/DiscountBadge';
import Classification from '@/components/Classification';
import Favorite from '@/components/grid/Favorite';
import ripple from '@/directives/ripple';
import ImageFluid from '@/components/ImageFluid';
import CountdownTimer from '@/components/product/countdown/CountdownTimer.vue';

export default {
    name: 'GridTile',
    components: { CountdownTimer, ImageFluid, Favorite, Classification, DiscountBadge, Price, ImageLazy },
    directives: { ripple },
    props: {
        block: {
            type: [Object, Product, ContentBlock, ValueVoucher],
            required: true
        },
        highest: Boolean
    },
    computed: {
        title() {
            if (this.block.hotelName) {
                return this.block.hotelName;
            }
            return this.block.title;
        },
        duration() {
            const nights = this.block.nights;
            if (nights && nights.length) {
                const dayString = this.$t('Generic.day', null, nights[nights.length - 1] + 1); //(nights.length === 1 && +nights[0] === 1) ? 'Tag' : 'Tage';
                const variants = this.block.nights.reduce((final, current, index, nightsArray) => {
                    const days = (current + 1).toString();
                    const isLast = nightsArray.length === index + 1;
                    const itemString = index ? (isLast ? ' & ' : ', ').concat(days) : days;
                    return `${final}${itemString}`;
                }, '');
                return `<span class="text-truncate">${variants}</span><span style="margin-left: 3px;">${dayString}</span>`;
            }
            return null;
        },
        banner() {
            return this.block.bannerText || (this.block.newProduct && this.$t('Generic.newProduct'));
        },
        bannerStyle() {
            if (this.block.bannerBackgroundColor) {
                return 'background-color: ' + this.block.bannerBackgroundColor + ';';
            }
            return '';
        },
        productPrice() {
            return this.block.price && this.block.price.regularPriceValues;
        },
        showDuration() {
            return this.$store.state.globalMeta.durationVisible && this.duration;
        },
        showHeader() {
            return (
                !this.hasMiddleTitle &&
                (this.title ||
                    this.block.subtitle ||
                    this.$getSafe(this.block, 'hotelClassification.classificationValue') ||
                    this.showDuration)
            );
        },
        hasMiddleTitle() {
            //not block type agnostic, but since block.text exists in the content block as well, it is a better condition to check
            return this.block.type === 'content' || this.$getSafe(this.block, 'link.empty');
        }
    }
};
</script>

<style lang="scss" scoped>
@use 'sass:map';
@use '@/styles/variables';

$padding: 12px;

.grid-tile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 7px map-get(variables.$grid, 'tile-shadow-blur') 0 hsla(0, 0%, 0%, 0.1);
    text-decoration: none;
    border: 1px solid var(--border-color); //explicit light border looks better with box-shadow, outline gets clipped on top
    color: var(--body-color);
    background: var(--product-block-bg);
    font-size: 18px;

    &--content {
        padding: 0;
        position: relative;

        .grid-tile__text {
            color: hsl(0, 0%, 100%);
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translateY(-50%);
            padding: 10px;
            font-size: 24px;
            z-index: 2;
            text-align: center;
            background: hsla(0, 0%, 0%, 0.7);
            margin-top: 0;
        }
    }

    &--dimmed {
        .grid-tile__media-wrapper {
            opacity: 0.3;
        }

        .grid-tile__text {
            background: hsla(0, 0%, 0%, 0.5);
        }
    }
}

.grid-tile__header,
.grid-tile__text {
    padding: $padding;
}

.grid-tile__header {
    flex: 0 0 var(--grid-tile-header-height); //prevents from onload shifts for themed tiles
}

.grid-tile__title {
    color: var(--primary);
    line-height: 1.2;
    margin: 0;
    font-size: inherit;
}

.grid-tile__subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    line-height: normal;

    .classification {
        margin: 0;
    }
}

.grid-tile__duration {
    line-height: normal;
}

.grid-tile__media-wrapper {
    position: relative;
    flex: 1 0 auto;
}

.grid-tile__media-image {
    //aspect-ratio: 16 / 11.73; //Safari/Chrome iOS :-(
    position: absolute; //prevents from affecting parent flex wrapper, oversizing it. Starts to respect width and height properties of lazy-image
}

.grid-tile__sold-out {
    background-image: url('~assets/soldout.png');
    background-repeat: repeat;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    color: hsl(0, 0%, 100%);
}

.grid-tile__sold-out-text {
    background-color: rgba(variables.$soldout-color, 0.75);
    padding: 15px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
}

.grid-tile__media-top,
.grid-tile__media-bottom {
    display: flex;
    position: absolute;
    width: 100%;
    justify-content: space-between;
}

.grid-tile__media-top {
    top: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7), transparent);
    align-items: flex-start;

    & > * {
        //preserving the gradient offset for better readability
        margin-bottom: 12px !important;
    }
}

.grid-tile__media-top-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.grid-tile__media-bottom {
    bottom: 0;
    background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.7));
    align-items: flex-end;

    & > * {
        //preserving the gradient offset for better readability
        margin-top: 12px !important;
    }
}

.grid-tile__media-element {
    margin: $padding;
    color: hsl(0, 0%, 100%);
}

.grid-tile__text {
    font-size: 18px;
    overflow: hidden;
    line-height: 1.2;
    flex: 0 0 65px;
}

.grid-tile__banner {
    background-color: var(--primary);
    font-size: 15px;
    line-height: 1;
    padding: 5px;
    text-transform: uppercase;
    text-align: center;
    white-space: normal; //to prevent from overlapping the right media element
}

.grid-tile__direct-booking {
    font-size: 26px;
    flex-shrink: 0;
    margin-left: auto;
}

.grid-tile__favorite {
    margin-left: auto;
}

.grid-tile__clamp {
    text-overflow: ellipsis;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    width: 100%;
    overflow: hidden;
    white-space: normal;
}

.grid-tile__countdown-timer {
    margin-inline: $padding;
    margin-top: $padding;
}
</style>
