<template>
    <button type="button" class="pwa-banner btn">
        <span style="max-width: 230px">{{ $t('pwa.banner.text') }}</span>
    </button>
</template>

<script>
export default {
    name: 'PWABanner'
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';

.pwa-banner {
    width: 280px;
    height: 140px;
    background-color: variables.$primary;
    border-radius: 5px;
    color: variables.$white;
    text-align: center;
    box-shadow: 0 2px 12px 0 rgba(variables.$primary, 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
</style>
