/**
 * @class
 * @constructor
 * @param {String | null} ident
 * @param {String} url
 * @param {String} type
 * @param {String} message
 * @param {Object} [payload]
 */
class Log {
    constructor({ ident, url, type, message, payload }) {
        this.ident = ident;
        this.url = url;
        this.type = type || 'Error type';
        this.message = message || 'Error message';
        this.payload = payload;
        this.timestamp = new Date().toLocaleString('de-DE');
    }
}

export default function (context, inject) {
    let logQueue = {};
    let logTimeout = null;

    /**
     * Sends log to the backend
     * {Error} error
     */
    const log = error => {
        let type = error.name || 'Error';
        const name = error.name || 'Generic error';
        const message = error.message || error; // some js errors are strings only

        if (error instanceof DOMException) {
            type = message.includes(
                "Failed to execute 'appendChild' on 'Node': This node type does not support this method."
            )
                ? 'Responsive Failure'
                : name;
        }

        // filtering out the ResizeObserver error that doesn't cause
        else if (
            ~message.indexOf('ResizeObserver loop limit exceeded') ||
            ~message.indexOf('ResizeObserver loop completed')
        ) {
            if (process.env.NODE_ENV === 'development') {
                console.warn('Filtering out the ResizeObserver error. No effect on the code');
            }
            return;
        }

        const errorLog = new Log({
            ident: context.store.state.ident,
            url: window.location.href,
            type,
            message
        });

        logQueue[`${errorLog.type} - ${errorLog.timestamp}`] = errorLog;

        clearTimeout(logTimeout);
        logTimeout = setTimeout(() => {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error being reported to syslog!');
            }
            Object.values(logQueue).forEach(errorLog => {
                context.$axios.post('shop/syslog', errorLog);
            });
            logQueue = {};
        }, 1000);
    };

    //Global errors outside Vue
    // TODO: consider window.onerror implementation as it has more arguments
    //window.onerror = log;
    window.addEventListener('error', log);

    //Available from inside Vue and Nuxt context
    inject('log', log);
}
