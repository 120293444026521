import Block from '../cms/Block';

/**
 * @class
 * @extends Block
 */
export default class ValueVoucher extends Block {
    /**
     * @constructor
     * @param {number} id
     * @param {number} amount
     * @param {ProductPriceInformation} [price]
     * @param {string} [subtitle]
     * @param {string} [title]
     * @param {string} bannerText
     * @param {boolean} soldOut
     * @param {...object} rest
     */
    constructor({ id, amount, price, subtitle, bannerText, bannerBackgroundColor, soldOut, title, ...rest }) {
        super(rest);
        Object.assign(this, {
            id,
            amount,
            price,
            subtitle,
            bannerText,
            bannerBackgroundColor,
            soldOut,
            title
        });
    }
}
