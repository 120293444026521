import Block from '@/models/pre-ts/cms/Block';

/**
 * @class
 * @extends Block
 */
export default class Product extends Block {
    /**
     * @constructor
     * @param {number} id
     * @param {string} locationText
     * @param {string} hotelName
     * @param {string} bannerText
     * @param {HotelClassification} hotelClassification
     * @param {ProductPriceInformation} price
     * @param {Array<number>} nights
     * @param {boolean} directBookingAvailable
     * @param {boolean} newProduct
     * @param {boolean} soldOut
     * @param {boolean} favorite
     */
    constructor({
        id,
        locationText,
        hotelName,
        bannerText,
        hotelClassification,
        price,
        nights,
        directBookingAvailable,
        newProduct,
        soldOut,
        favorite,
        ...rest
    }) {
        super(rest);
        Object.assign(this, {
            id,
            locationText,
            hotelName,
            bannerText,
            hotelClassification,
            price,
            nights,
            directBookingAvailable,
            newProduct,
            soldOut,
            favorite
        });
    }
}
