<template>
    <div class="details-teaser">
        <div v-if="hasContent" class="teaser-text container">
            <template v-if="hasHotelDetails">
                <div class="teaser-subtitle mb-0" :class="{ 'mb-3': estimatedClassificationVisible }">
                    <Classification
                        v-if="hotelDetails.hotelClassification"
                        :hotelClassification="hotelDetails.hotelClassification"
                        estimateBelowStars
                    ></Classification>

                    <Link v-if="hotelDetails.hotelPageVisible" :link="hotelLink" type="hotel" class="hotel-link">
                        <h1 class="hotel-name text-truncate">{{ hotelDetails.name }}</h1>
                    </Link>
                </div>

                <component :is="titleComponent" class="teaser-title my-0">{{ title }}</component>
            </template>

            <h1 v-else-if="title" class="teaser-title">{{ title }}</h1>

            <template v-if="subtitle">
                <div class="teaser-subtitle" v-html="subtitle" />
            </template>

            <slot></slot>
        </div>
    </div>
</template>

<script>
import Classification from '@/components/Classification';

export default {
    name: 'DetailsTeaser',
    components: {
        Classification
    },
    props: {
        title: String,
        /**
         * value voucher specific
         */
        subtitle: {
            type: String,
            required: false
        },
        /**
         *  hotel voucher specific
         */
        hotelDetails: {
            type: Object,
            required: false
        }
    },
    computed: {
        hotelLink() {
            if (!this.hotelDetails) {
                return;
            }

            const { id, webname } = this.hotelDetails;
            return {
                hotelId: id,
                webnames: [webname]
            };
        },
        estimatedClassificationVisible() {
            const hotelDetails = this.hotelDetails || {};
            return (
                this.$getSafe(hotelDetails, 'hotelClassification.estimatedClassification') &&
                this.$getSafe(hotelDetails, 'hotelClassification.classificationVisible')
            );
        },
        hasHotelDetails() {
            return this.hotelDetails && !!Object.keys(this.hotelDetails).length;
        },
        hasContent() {
            return this.title || this.subtitle || this.hasHotelDetails || this.$slots.default;
        },
        titleComponent() {
            return this.$getSafe(this, 'hotelDetails.hotelPageVisible') ? 'h2' : 'h1';
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/mixins';

.details-teaser {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
    width: 100%;
    bottom: 0; //parent Teaser sets absolute positioning

    .teaser-text {
        color: var(--details-teaser-color);
        z-index: 3;
        padding-top: 40px;
        padding-bottom: 20px;

        .hotel-link {
            color: var(--details-teaser-color);
            text-decoration: underline;
        }

        .teaser-title {
            font-size: 28px;
            line-height: 35px;
            font-weight: 700;
            margin: 6px 0;
        }

        .teaser-subtitle {
            font-size: 20px;
            margin-bottom: 3px;
            display: flex;
            align-items: center;

            .classification {
                margin-top: 0;
            }

            .hotel-name {
                font-size: 22px;
                margin-bottom: 0;
            }

            ::v-deep {
                p {
                    margin-bottom: 0;
                }
            }
        }

        @include mixins.mobile-only {
            padding-top: 30px;
            padding-bottom: 10px;

            .teaser-title {
                font-size: 26px;
            }
        }
    }
}
</style>
