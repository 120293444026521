export default ({ $axios, redirect, store, isDev, $config, app }) => {
    $axios.defaults.headers.common['x-api-key'] = $config.apiKey;

    //Uncomment when basic auth is required
    /*$axios.defaults.auth = {
        username: $config.httpUsername,
        password: $config.httpPassword
    };*/

    $axios.onRequest(config => {
        //lowercase "accept" key gets concatenated with request specific ones
        if (store.state.webP) {
            config.headers.get['x-accept-images'] = 'image/webp';
        }

        if (store.state.ident) {
            config.headers.common['x-mvident'] = store.state.ident;
        }

        if (store.state.token) {
            config.headers.common['x-access-key'] = store.state.token;
        }

        if (store.state.multiVoucherCode) {
            config.headers.common[$config.voucherKeyName] = store.state.multiVoucherCode;
        }

        if (store.state.multiVoucherProduct) {
            config.headers.common[$config.voucherProductKeyName] = store.state.multiVoucherProduct;
        }

        config.headers.common['x-agent'] = 'animod-ssr';

        if (app.$cookies.xop) {
            config.headers.common['x-op'] = app.$cookies.xop;
        }

        if (isDev) {
            console.log('Making request to ' + config.baseURL + '/' + config.url);
        }
    });

    $axios.onError(error => {
        //!Redirection is done in a fetchResource
    });
};
