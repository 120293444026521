<template>
    <div class="countdown">
        <header v-if="showHeading && !isExpired" class="countdown__header">
            {{ $t('Countdown.remainingText') }}
        </header>

        <div class="countdown-timer">
            <template v-if="!isExpired">
                <span class="countdown-timer__item countdown-timer__days">
                    <span class="countdown-timer__value">{{ days }}</span>
                    <span class="countdown-timer__text">{{ this.$t('Generic.day', null, days) }}</span>
                </span>
                <span class="countdown-timer__item countdown-timer__hours">
                    <span class="countdown-timer__value">{{ hours }}</span>
                    <span class="countdown-timer__text">{{ this.$t('Generic.hourShort') }}</span>
                </span>
                <span class="countdown-timer__item countdown-timer__minutes">
                    <span class="countdown-timer__value">{{ minutes }}</span>
                    <span class="countdown-timer__text">{{ this.$t('Generic.minuteShort') }}</span>
                </span>
                <span class="countdown-timer__item countdown-timer__seconds">
                    <span class="countdown-timer__value">{{ seconds }}</span>
                    <span class="countdown-timer__text">{{ this.$t('Generic.secondShort') }}</span>
                </span>
            </template>

            <span v-else class="countdown-timer__item countdown-timer__item--expired"
                >{{ $t('Countdown.expired') }}
            </span>
        </div>
    </div>
</template>

<script>
const minuteInMs = 1000 * 60;
const hourInMs = minuteInMs * 60;
const dayInMs = hourInMs * 24;

export default {
    name: 'CountdownTimer',
    props: {
        availableUntil: {
            type: String,
            required: true
        },

        showHeading: Boolean
    },
    data() {
        return {
            intervalId: null,
            currentDate: new Date()
        };
    },
    computed: {
        availableUntilDate() {
            return new Date(this.availableUntil);
        },

        remainingMilliseconds() {
            return Math.abs(this.currentDate - this.availableUntilDate);
        },

        days() {
            return Math.floor(this.remainingMilliseconds / dayInMs);
        },

        hours() {
            return Math.floor((this.remainingMilliseconds % dayInMs) / hourInMs);
        },

        minutes() {
            return Math.floor((this.remainingMilliseconds % hourInMs) / minuteInMs);
        },

        seconds() {
            return Math.floor((this.remainingMilliseconds % minuteInMs) / 1000);
        },

        isExpired() {
            return this.availableUntilDate < this.currentDate;
        }
    },

    watch: {
        isExpired: {
            handler(expired) {
                expired && this.$emit('expired');
            },
            immediate: true
        }
    },

    created() {
        this.intervalId = setInterval(() => {
            this.currentDate = new Date();
        }, 1000);
    },

    beforeDestroy() {
        clearInterval(this.intervalId);
    }
};
</script>

<style scoped lang="scss">
.countdown {
    container-type: inline-size;

    &__header {
        font-size: 0.875rem;
        margin-bottom: 0.5rem;
    }
}

.countdown-timer {
    display: inline-grid;
    grid-template-columns: repeat(4, minmax(0, 2.5rem));
    gap: 0.5rem;
    color: var(--body-color);
    place-items: stretch;
    width: 184px; // regular width, for more cases. Need to unfortunately set the width or countdown will shrink in some cases
    height: 2.5rem; // defined height helps prevent cls when expiring
    font-size: 1rem;

    @container (width > 310px) {
        & {
            width: 280px; // bigger width. Necessary to be set to prevent shrinking
            height: 4rem; // defined height helps prevent cls when expiring
            font-size: 1.5rem;
            grid-template-columns: repeat(4, minmax(0, 4rem));
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.375rem;
        border-radius: 0.25rem;
        background-color: var(--white);
        aspect-ratio: 1 / 1;
        gap: 2px;

        &--expired {
            grid-column: 1 / -1;
            aspect-ratio: auto;
            font-weight: bold;
        }
    }

    &__value,
    &__text {
        line-height: 1;
    }

    &__value {
        font-weight: bold;
    }

    &__text {
        font-size: 0.625em;
    }
}
</style>
