import Vue from 'vue';
import PWABannerGrid from '@/components/mobile/PWABannerGrid';

let deferredPrompt;
let installSource = 'browser';

/*
    In order to test in development mode:
        event = new Event('beforeinstallprompt')
        window.dispatchEvent(event)

    The prompt won't be opened
 */

window.addEventListener('beforeinstallprompt', async (e) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    if (window.$nuxt.$store.getters.isMainShop) {
        e.preventDefault();

        deferredPrompt = e;
        showInstallPromo();

        if (!window.$nuxt.$gtm) {
            return;
        }

        window.$nuxt.$gtm.pushEvent('pwa.promo-shown');
    }
});

/**
 * Google Chrome Devs use this, but MDN states as deprecated
 */
window.addEventListener('appinstalled', () => {
    // Hide the app-provided install promotion
    //removeInstallPromo();

    deferredPrompt = null;

    //if the page is hidden, ignore
    if (document.visibilityState !== 'visible') {
        return;
    }

    window.$nuxt.$gtm.push({
        event: 'pwa.app-installed',
        source: installSource,
    });
});

function showInstallPromo() {
    if (location.pathname === '/') {
        const grids = document.querySelectorAll('.grid');
        if (!grids.length) return false;

        //Vue.extend looses instantiated store, therefore manually injecting. Nuxt-specific crutch
        Vue.prototype.$store = window.$nuxt.$store;
        //feeding Vue itself as this otherwise $t is missing
        const NewGridClass = Vue.extend.call(Vue, PWABannerGrid);
        const newGridInstance = new NewGridClass();
        newGridInstance.$mount();

        const banner = newGridInstance.$el.querySelector('.pwa-banner');

        banner &&
            banner.addEventListener('click', async () => {
                installSource = 'promo-banner';

                newGridInstance.$el.remove();

                deferredPrompt.prompt();

                const { outcome } = await deferredPrompt.userChoice;

                try {
                    if (outcome === 'accepted') {
                        window.$nuxt.$gtm.pushEvent('pwa.app-installing');
                    }
                    //outcome === "dismissed"
                    else {
                        window.$nuxt.$gtm.pushEvent('pwa.app-dismissed');
                        installSource = null;
                    }
                    deferredPrompt = null;
                } catch (e) {
                    e.name = 'Cannot process PWA installation flow';
                    window.$nuxt.$log(e);
                }
            });

        grids.item(0).parentNode.insertBefore(newGridInstance.$el, grids.item(0).nextSibling);
    }
}

/**
 * Detects whether the page was loaded in a browser or in an app
 * @returns {string}
 */
function getDisplayMode() {
    if (process.client) {
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
        if (document.referrer.startsWith('android-app://')) {
            return 'twa';
        } else if (navigator.standalone || isStandalone) {
            return 'standalone';
        }
        return 'browser';
    }
}
