const generateHashId = (length = 9) => {
    return Math.random().toString(36).substr(2, length);
};

const POSITION = {
    TOP: 'top',
    BOTTOM: 'bottom',
    LEFT: 'left',
    RIGHT: 'right'
};

const defaults = {
    position: POSITION.TOP,
    delay: 200,
    offset: 0,
    helpCursor: true,
    ignoreDisabled: true,
    onUpdate: null,
    zIndex: null,
    eager: false,
    useAnchorAPI: false
};

const getParents = element => {
    // Set up a parent array
    const parents = [];
    // Push each parent element to the array
    for (; element && element !== document; element = element.parentNode) {
        parents.push(element);
    }
    return parents;
};

const getCoords = element => {
    const box = element.getBoundingClientRect();

    return {
        top: Math.round(box.top + pageYOffset),
        left: Math.round(box.left + pageXOffset),
        right: Math.round(box.left + pageXOffset + element.offsetWidth),
        bottom: Math.round(box.top + pageYOffset + element.offsetHeight),
        centerX: Math.round(box.left + pageXOffset + element.offsetWidth / 2),
        centerY: Math.round(box.top + pageYOffset + element.offsetHeight / 2)
    };
};

const getBoundTooltip = element => {
    return document.getElementById(element.dataset.tooltip);
};

const getOptions = value => {
    const userOptions = typeof value === 'string' ? { content: value } : value;
    return Object.assign({}, defaults, userOptions);
};

const setTooltipPosition = (element, options) => {
    if (!element || !(element instanceof Element)) {
        return;
    }

    const tooltipEl = getBoundTooltip(element);

    if (!tooltipEl || !(tooltipEl instanceof Element)) {
        return;
    }

    if (options.zIndex) {
        tooltipEl.style.zIndex = options.zIndex;
    }

    if (options.useAnchorAPI && 'positionAnchor' in tooltipEl.style) {
        return;
    }

    const elementPosition = getCoords(element);

    const { marginTop, marginBottom, marginLeft, marginRight } = getComputedStyle(tooltipEl);
    let left, top;

    if (options.position === POSITION.TOP) {
        left = elementPosition.centerX - tooltipEl.offsetWidth / 2;
        top = elementPosition.top - tooltipEl.offsetHeight - (parseInt(marginBottom) || 0) - options.offset;
    } else if (options.position === POSITION.RIGHT) {
        left = Math.min(
            elementPosition.right + (parseInt(marginLeft) || 0) + options.offset,
            document.body.offsetWidth - tooltipEl.offsetWidth
        );
        top = elementPosition.centerY - tooltipEl.offsetHeight / 2;
    } else if (options.position === POSITION.BOTTOM) {
        left = elementPosition.centerX - tooltipEl.offsetWidth / 2;
        top = elementPosition.bottom + (parseInt(marginTop) || 0) + options.offset;
    } else if (options.position === POSITION.LEFT) {
        left =
            Math.max(10, elementPosition.left - tooltipEl.offsetWidth - (parseInt(marginRight) || 0)) - options.offset;
        top = elementPosition.centerY - tooltipEl.offsetHeight / 2;
    }

    tooltipEl.style.transform = `translate3d(${left}px, ${top}px, 0)`;
};

//Checking if element is not a child of a fixed of sticky parents
const willScroll = element => {
    return getParents(element).some(parent => {
        const position = getComputedStyle(parent).position;
        return position === 'fixed' || position === 'sticky';
    });
};

const appendTooltip = (element, options) => {
    element.dataset.tooltip = `tooltip-${generateHashId()}`;

    if (options.helpCursor) {
        element.style.cursor = 'help';
    }

    const tooltipEl = document.createElement('div');
    tooltipEl.id = element.dataset.tooltip;
    //IE doesn't support multiple params of Element.classList.add
    tooltipEl.classList.add('tooltip');
    tooltipEl.classList.add('tooltip-hidden');
    tooltipEl.classList.add(options.position);

    // https://developer.chrome.com/blog/anchor-positioning-api
    if (options.useAnchorAPI && 'positionAnchor' in tooltipEl.style) {
        // Kinda works, but fails for anchors in a modal window. Browser support is bad
        element.style.anchorName = `--${element.dataset.tooltip}`;
        tooltipEl.style.positionAnchor = `--${element.dataset.tooltip}`;
        tooltipEl.style.position = 'fixed';
        //tooltipEl.style.insetArea = 'top';
        tooltipEl.style.positionTryOptions = 'flip-block flip-inline';
        /*  Position bottom of anchored elem at top of anchor  */
        tooltipEl.style.inset = 'auto';

        tooltipEl.style.bottom = 'anchor(top)';
        /*  Center  */
        tooltipEl.style.justifySelf = 'anchor-center';
    } else {
    }

    const tooltipArrow = document.createElement('div');
    tooltipArrow.classList.add('tooltip-arrow');

    const tooltipContent = document.createElement('div');
    tooltipContent.classList.add('tooltip-content');
    tooltipContent.innerHTML = options.content;

    tooltipEl.appendChild(tooltipArrow);
    tooltipEl.appendChild(tooltipContent);
    document.body.appendChild(tooltipEl);

    return tooltipEl;
};

const showTooltip = function (tooltipEl, options) {
    tooltipEl.dataset.tooltipDelay = setTimeout(() => {
        // disabled state may have changed after tooltip init. Timeout is better
        if (!options.ignoreDisabled && tooltipEl.disabled) {
            return;
        }

        setTooltipPosition(this, options);
        tooltipEl.classList.remove('tooltip-hidden');
    }, options.delay);
};

const hideTooltip = function (tooltipEl, options) {
    clearTimeout(tooltipEl.dataset.tooltipDelay);
    tooltipEl.classList.add('tooltip-hidden');
};

const onScroll = function (element, options) {
    const timeout = element.dataset.scrollTimeout;
    timeout && clearTimeout(timeout);
    element.dataset.scrollTimeout = setTimeout(() => {
        setTooltipPosition(element, options);
    }, 100);
};

export default {
    bind(element, binding) {
        if (binding.value) {
            const options = getOptions(binding.value);
            const tooltipEl = appendTooltip(element, options);

            if (options.eager) {
                showTooltip.call(element, tooltipEl, options);
            } else {
                element.addEventListener('mouseenter', showTooltip.bind(element, tooltipEl, options));
            }

            element.addEventListener('mouseout', hideTooltip.bind(element, tooltipEl, options));
        }
    },
    componentUpdated(element, binding) {
        //https://v2.vuejs.org/v2/guide/custom-directive.html#Hook-Functions
        if (binding.value) {
            const options = getOptions(binding.value);
            const tooltipEl = getBoundTooltip(element);
            if (options.onUpdate && typeof options.onUpdate === 'function') {
                options.onUpdate(element, binding, tooltipEl);
            }
        }
    },
    unbind(element) {
        const tooltipEl = getBoundTooltip(element);
        if (tooltipEl) {
            const tooltipEl = getBoundTooltip(element);
            tooltipEl.remove();
            element.removeEventListener('mouseenter', showTooltip);
            element.removeEventListener('mouseout', hideTooltip);
        }
    }
};
