<template>
    <ErrorContainer id="error" :title="title">
        <template v-slot:text>
            <translate v-if="hasTranslations" tag="p" path="500.text" class="mb-0">
                <a slot="phone" href="tel:0221 - 933 74 100">0221 - 933 74 100</a>
                <a slot="email" href="mailto:info@animod.de">{{ $t('Contact.email') }}</a>
            </translate>
            <p v-else class="mb-0">
                Leider ist ein technischer Fehler aufgetreten. Wir bemühen uns um eine rasche Behebung des Problems - in
                eiligen Fällen erreichen Sie uns telefonisch unter oder per E-Mail an
                <a href="mailto:info@animod.de">info@animod.de</a>.
            </p>
        </template>
    </ErrorContainer>
</template>

<script>
import ErrorContainer from '@/components/error/ErrorContainer';
import AjaxError from '@/models/AjaxError';
import getHead from '@/mixins/getHead';

export default {
    name: 'error',
    components: { ErrorContainer },
    mixins: [getHead],
    //IMPORTANT. wtf feature, not a bug: nuxt page hooks don't work in error.vue page even it is treated as a page. Sorry for the tautology
    //IMPORTANT, wtf feature, not a bug: error page has an index route
    //2.12+ fetch, has access to this and is being at least called
    layout: 'combined', //layout middleware, like any page middleware, is not executed on error page ¯\_(ツ)_/¯
    head() {
        return this.getHead({
            pageTitle: this.title.concat(' | Animod')
        });
    },
    props: {
        error: [AjaxError, Object]
    },
    computed: {
        title() {
            return this.hasTranslations ? this.$t('500.header') : 'Entschuldigung';
        },
        hasTranslations() {
            return this.$i18n && this.$i18n.localeExists(this.$store.state.language);
        }
    },
    mounted() {
        // after silent error about missing component instance decided to log the error (client-only)
        // Might help in production as well
        console.error(this.error);
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/mixins';

#error {
    background-color: var(--body-bg);

    .title {
        font-size: 20px;
        font-weight: 700;

        @include mixins.mobile-only {
            text-transform: uppercase;
        }
    }

    .btn-info {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        padding: 18px;
        line-height: 1em;
    }
}
</style>
