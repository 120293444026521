<template>
    <span class="price">
        <span class="price-values d-inline-flex">
            <!--extra span wrapper to separate price from slot (usually discount badge). Makes possible to align from and per person as baseline-->
            <span v-if="showPrefixComputed" class="prefix" :class="{ small: smallPrefix }">{{ prefix }}</span>

            <span class="price-value">
                {{ firstAvailablePrice }}
            </span>
        </span>

        <slot>
            <DiscountBadge v-if="showDiscount" :discount="discountPercentage" class="price__discount" />
        </slot>

        <span v-if="!hideHelp" class="help-text">
            {{ helpText }}
        </span>
    </span>
</template>

<script>
import price from '@/mixins/price';
import DiscountBadge from '@/components/DiscountBadge';
import priceModes from '@/models/priceModes';

export default {
    name: 'PriceNew',

    mixins: [price],

    components: {
        DiscountBadge
    },
    props: {
        prefix: {
            type: String,
            default() {
                return this.$t('Price.prefixDefault');
            }
        },
        showPrefix: Boolean,
        smallPrefix: Boolean,
        hideHelp: Boolean,
        hideCents: Boolean,
        hideDiscount: Boolean,
        preferredMode: {
            type: String,
            validator(value) {
                return [...priceModes].some(mode => mode.property === value);
            }
        }
    },
    computed: {
        showPrefixComputed() {
            return this.showPrefix || this.priceModeProperty !== priceModes.package.property;
        },
        showDiscount() {
            return !this.hideDiscount && this.isDiscountVisible && this.discountPercentage;
        },
        helpText() {
            if (this.priceModeProperty === priceModes.package.property) {
                return this.$t('Gesamtpreis');
            } else if (this.priceModeProperty === priceModes.perPerson.property) {
                return this.$t('Price.perPerson');
            } else if (this.priceModeProperty === priceModes.perNight.property) {
                return this.$t('Price.perPersonPerNight');
            }
        }
    }
};
</script>

<style scoped lang="scss">
.price {
    position: relative;
    //taken from the tile price styles. Can be moved to the property
    font-weight: bold;
    font-size: 22px;

    .price-value,
    .prefix {
        align-self: baseline;
        display: inline-block;
    }

    .prefix {
        line-height: 1;
        margin-right: 5px; /*margin should be present (product-details select to test)*/
        font-weight: inherit;

        &.small {
            font-size: 60%;
            font-weight: inherit;
        }
    }

    .help-text {
        font-size: 60%;
        line-height: normal;
        display: block;
        margin-top: -3px;
    }

    .price-values,
    &__discount {
        vertical-align: middle;
    }

    &__discount {
        font-size: 0.8em;
        display: inline-flex;
        margin-left: 3px;
    }
}
</style>
