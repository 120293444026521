import AjaxError from '@/models/AjaxError';
//import { Context } from '@nuxt/types';

/**
 * Processes all page ajax calls and redirects if necessary.
 * Vuex should only hold state rather product redirects directly.
 * @function
 * @param {Context} context
 * @param {Promise} vuexActionPromise
 * @return {Promise<void>}
 */
export default async function (context, vuexActionPromise) {
    try {
        //return in order to distinguish successful and error state in a caller function
        const response = await vuexActionPromise;
        //anything intermediate
        return response;
    } catch (e) {
        const ajaxError = new AjaxError(e, context.route.fullPath);
        context.isDev && console.error(ajaxError);

        if (ajaxError.isNotFound) {
            //here store gets cleaned
            context.redirect({ name: 'notFound', query: { from: ajaxError.failedRoute } });
        } else if (ajaxError.isServerError) {
            context.error(ajaxError);
        } else if (ajaxError.isUnauthorized && context.route.name !== 'accessCode') {
            //context.redirect will clear vuex store. This behavior is a "feature, not a bug".
            //query string param can be reentered manually when attempting to load access-code page
            //cookie serves as a temporary measure to preserve state between context.redirect and nuxtServerInit
            context.$cookies.setCookie('unauthorized', 'true', 'session');
            //here store gets cleaned. Can't rely on vuex auth state flags
            context.redirect({
                name: 'accessCode',
                query: {
                    redirect: ajaxError.failedRoute,
                    errorType: ajaxError.errorType
                }
            });
        }
    }
}
