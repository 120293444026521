/**
 * Class representing a single grid
 * @class
 */
class Grid {
    /**
     * Create a grid
     * @constructor
     * @param {String} title
     * @param {Array} blocks
     * @param {String} content
     * @param {Number} blocksPerRow
     */
    constructor (title, blocks, content, blocksPerRow = 3, page, numberOfPages) {
        const id = Math.random(100).toString(16).substring(2);
        Object.assign(this, {
            id,
            title,
            blocks,
            content,
            blocksPerRow,
            page,
            numberOfPages
        })
    }
}

export default Grid;
