/**
 * @class
 */
export default class Link {
    /**
     * @constructor
     * @param {number|string} [productId]
     * @param {number|string} [valueVoucherId]
     * @param {Array} [webnames]
     * @param {number|string} [hotelId]
     * @param {string} [queryString]
     * @param {string} [page]
     * @param {string} [search]
     */
    constructor({ productId, valueVoucherId, webnames, hotelId, queryString, page, search, ...rest }) {
        Object.assign(this, {
            productId,
            valueVoucherId,
            webnames,
            hotelId,
            queryString,
            page,
            search,
            ...rest
        });
    }
}
