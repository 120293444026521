<!--
    Wrapper for every specific teaser. Mixes background with gradient
-->

<template>
    <div class="teaser" ref="teaser">
        <GiftBoxBanner v-if="giftBoxBannerVisible" />
        <ImageFluid :src="url" class="teaser-image" :alt="alt" :eager="forceLoad" :width="width" :height="height" />
        <slot />
    </div>
</template>

<script>
import GiftBoxBanner from '@/components/banners/GiftBoxBanner';
import ImageFluid from '@/components/ImageFluid';

export default {
    name: 'Teaser',
    components: {
        ImageFluid,
        GiftBoxBanner
    },
    props: {
        backgroundUrl: { type: String },
        alt: String,
        giftBoxBannerVisible: Boolean,
        forceLoad: {
            type: Boolean,
            default: true
        },
        width: {
            type: [Number, String],
            default: 1440
        },
        height: {
            type: [Number, String],
            default: 560
        }
    },
    computed: {
        url() {
            if (this.backgroundUrl) {
                return this.backgroundUrl;
            } else if (this.$store.state.webP) {
                return require('@/assets/webp/teasers/teaser_fallback-1440x560.webp');
            }
            return require('@/assets/teasers/teaser_fallback-1440x560.jpg');
        }
    },
    updated() {
        this.$parent.$emit('updatedTeaser');
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/bootstrap/breakpoints' as breakpoints;

$aspect-ratio: 40%; // (560px * 100) / 1440 (rounded ceil)

.teaser {
    overflow: hidden;
    width: 100%;
    position: relative;

    @include breakpoints.media-breakpoint-only(xl) {
        height: var(--teaser-height-xl);
        max-height: var(--teaser-height-xl);
    }

    @include breakpoints.media-breakpoint-only(lg) {
        height: var(--teaser-height-lg);
        max-height: var(--teaser-height-lg);
    }

    @include breakpoints.media-breakpoint-down(variables.$mobile-breakpoint-max) {
        padding-top: variables.$teaser-aspect-ratio;
        min-height: 200px;
    }

    @media screen and (max-height: 900px) and (min-width: breakpoints.breakpoint-min(lg)) {
        height: var(--teaser-height-lg);
        max-height: var(--teaser-height-lg);

        ::v-deep {
            .gift-box-banner {
                right: 40px;
                left: auto;
                top: 90px;
            }
        }
    }

    &.category-teaser {
        @include breakpoints.media-breakpoint-only(xl) {
            height: var(--teaser-category-height-xl) !important;
            max-height: var(--teaser-category-height-xl) !important;
        }
    }

    .teaser-image {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    ::v-deep > * {
        position: absolute;
    }
}
</style>
