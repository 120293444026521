import linkMixin from '@/mixins/getLink';

export default {
    name: 'Link',
    functional: true,
    props: {
        link: {
            type: Object,
            default() {
                return {
                    url: '#'
                };
            }
        }, //is not required. Sometimes we cannot rely on correct Link object provided, e.g. price mode nav item
        type: String,
        to: {
            type: [String, Object]
        },
        replace: Boolean,
        append: Boolean,
        mask: Boolean,
        maskTag: {
            // for more complex links like banners span is not a correct wrapper
            type: String,
            default: 'span'
        }
    },
    render(h, { props, data, slots, children, listeners, parent }) {
        const route = props.type
            ? linkMixin.methods.getLinkByType(props.link, props.type)
            : linkMixin.methods.getLink(props.link, parent.$store.state.ident);

        const resolved = parent.$router.resolve(props.to || route); //if url is e.g. 'www.animod.de', then router will resolve to current path!

        const url = props.link.url === '#' ? null : props.link.url;
        const isRelativeUrl = url && url.startsWith('/');

        let isCurrentPage = false;

        if (url) {
            isCurrentPage = isRelativeUrl && url === parent.$route.path;
        } else if (props.link.empty) {
            isCurrentPage = false;
        } else {
            isCurrentPage = parent.$route.path === (resolved.location.path || resolved.href);
        }

        const target = props.link.newWindow ? '_blank' : false;

        if (props.mask || isCurrentPage) {
            const classes = ['link-masked', { 'nuxt-link-exact-active': isCurrentPage }];

            if (Array.isArray(data.class)) {
                data.class.forEach(c => classes.push(c));
            } else if (typeof data.class === 'string' || typeof data.class === 'object') {
                classes.push(data.class);
            }

            return h(
                props.maskTag, //recommend to use anchor to preserve the styling and simulate interaction behavior
                {
                    ...data,
                    attrs: {
                        ...data.attrs
                        //href: '#'
                    },
                    class: classes,
                    on: {
                        click(e) {
                            e.preventDefault();

                            listeners.click && listeners.click(e);

                            if (!isCurrentPage) {
                                url && !isRelativeUrl
                                    ? window.open(url, '_self')
                                    : parent.$router.push(props.to || url || route);
                            }
                        }
                    },
                    style: {
                        cursor: 'pointer'
                    }
                },
                children
            );
        } else if (url && !isRelativeUrl) {
            return h(
                'a',
                {
                    ...data,
                    attrs: {
                        href: url,
                        target
                    }
                    //on: listeners
                },
                children
            );
        } else {
            // Link property with only url will resolve to # route
            const to = route === '#' && url ? url : route;

            return h(
                'NuxtLink',
                {
                    ...data,
                    nativeOn: {
                        click(e) {
                            listeners.click && listeners.click(e);
                        },
                        touchend(e) {
                            //listeners.click && listeners.click(e);
                        }
                    },
                    attrs: {
                        target
                    },
                    props: {
                        to,
                        replace: props.replace,
                        append: props.append
                        //event: ['click', 'touchend']
                    }
                },
                children
            );
        }
    }
};
