<template>
    <component
        :is="tag"
        class="favorite"
        :class="{ 'favorite--active': isFavorite, 'btn-icon': isIcon }"
        @click.stop.prevent="toggleFavorites"
    >
        <IconSvg filepath="banner/heart.svg" class="favorite__icon" />
        <slot />
    </component>
</template>

<script>
import Favorite from '@/models/pre-ts/customer/Favorite';
import ProductBlock from '@/models/pre-ts/product/Product';
import ValueVoucherBlock from '@/models/pre-ts/product/ValueVoucher';
import Link from '@/models/pre-ts/navigation/Link';

export default {
    name: 'Favorite',
    props: {
        /**
         * @property
         * Need entire product instance to construct favorites page grid from using localStorage only for now
         */
        product: {
            type: [Object, ProductBlock, ValueVoucherBlock],
            required: true
        },
        /**
         * @property
         * Span by default since grid tiles are anchors and thus cannot nest buttons
         */
        tag: {
            type: String,
            default: 'span'
        }
    },
    computed: {
        favoriteId() {
            return `${this.product.productCategory} - ${this.product.id}`;
        },
        isFavorite() {
            return this.favIndex !== -1;
        },
        favorites: {
            get() {
                return this.$store.state.favorites;
            },
            set(favorites) {
                this.$store.dispatch('setFavorites', favorites);
            }
        },
        favIndex() {
            return this.favorites.findIndex(f => f.favoriteId === this.favoriteId);
        },
        // turns product details product / value voucher to the grid tile-compatible object
        favoriteComputed() {
            let { id, teaserImageUrl, imageUrl, hotel, hotelName, title, text, link, hotelWebname, webname } =
                this.product;

            if (!link) {
                const idProperty = this.product.productCategory === 'valuevoucher' ? 'valueVoucherId' : 'productId';
                link = new Link({
                    [idProperty]: id,
                    webnames: hotelWebname ? [hotelWebname, webname] : [webname]
                });
            }

            return {
                ...this.product,
                //required props
                imageUrl: imageUrl || teaserImageUrl,
                link,
                //optional
                hotelName: hotelName || hotel,
                title: text ? title : null,
                text: text || title, //text is undefined on product details pages
                type: 'product' //still used to distinguish some ui elements specific for content block
            };
        },
        isIcon() {
            return this.tag === 'span';
        }
    },
    methods: {
        toggleFavorites() {
            if (!this.isFavorite) {
                let message;

                if (this.$noLocalStorage) {
                    message = this.$t('Favorites.noLocalStorage');
                } else {
                    this.addFavoriteToStorage();
                    message = this.$t('Favorites.saveMessage', {
                        url: '/' + (this.$t('Favorites.myFavorites.url') || 'favorites')
                    });
                }

                //console.log(message);
                //this.$addBottomFloat might be missing if FloatingBottom wasn't instantiated
                this.$addBottomFloat &&
                    this.$addBottomFloat('<div class="favorites nowrap">' + message + '</div>', null);
            } else {
                this.removeFavoriteFromStorage();
            }

            this.$emit('click', this.isFavorite);
        },
        addFavoriteToStorage() {
            const favorite = new Favorite({
                ...this.favoriteComputed,
                favoriteId: this.favoriteId,
                created: new Date().toLocaleDateString(this.$store.state.i18n.locale)
            });
            //making a shallow copy to avoid direct vuex store mutation
            const favorites = [...this.favorites];
            favorites.push(favorite);
            this.favorites = favorites;
        },
        removeFavoriteFromStorage() {
            if (this.isFavorite) {
                //making a shallow copy to avoid direct vuex store mutation
                const favorites = [...this.favorites];
                favorites.splice(this.favIndex, 1);
                this.favorites = favorites;
            }
        }
    }
};
</script>

<style scoped lang="scss">
.favorite {
    display: block;
    color: hsl(0, 0%, 100%);
    cursor: pointer;

    &--active {
        .favorite__icon {
            fill: currentColor;
        }
    }
}
</style>
