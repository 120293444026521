<template>
    <span class="discount-badge">
        <IconSvg filepath="discount.svg" class="discount-svg" />
        <span class="discount-value">{{ discountText }}</span>
    </span>
</template>

<script>
export default {
    name: 'DiscountBadge',
    props: {
        discount: [Number, String]
    },
    computed: {
        discountText() {
            if (typeof this.discount === 'number') {
                return `-${this.discount.toFixed(0)}%`;
            }
            return this.discount;
        }
    }
};
</script>

<style scoped lang="scss">
.discount-badge {
    padding: 5px 8px 5px 0.5em;
    position: relative;
    color: var(--discount-badge-color, var(--white));
    line-height: 1em;

    .discount-svg {
        fill: var(--discount-badge-bg, var(--primary));
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .discount-value {
        z-index: 2;
        position: relative;
        font-weight: bold;
    }
}
</style>
