/**
 * If used as a prototype - makes possible iterating over object's properties, e.x. in for..of loops
 * or to use destructuring
 *
 * Additionally it provides custom ability to infinitely iterate over object's property
 *
 * In order to use it properly, please use Object.setPrototypeOf(targetObject, iterableObject);
 */

const iterableObject = {
    //Iterator should return Object level next method.
    // Object level made for usage convenience, e.x. priceModes.next()
    [Symbol.iterator]() {
        return this;
    }
};

Object.defineProperties(iterableObject, {
    keys: {
        get() {
            return Object.keys(this);
        }
    },
    //Iterator specific
    next: {
        //be sure not to use return statement inside for..of otherwise the index might be saved as different from 0
        value: (function (index) {
            return function () {
                const current = this[this.keys[index]];
                const done = index === this.keys.length;

                if (!done) {
                    index++;
                    return {
                        done,
                        value: current
                    };
                } else {
                    index = 0;
                    return {
                        done
                    };
                }
            };
        })(0),
        enumerable: false
    },
    //Custom loop iteration
    nextLoop: {
        value(current) {
            let indexLoop = current
                ? [...this].findIndex(item => {
                      return JSON.stringify(current) === JSON.stringify(item);
                  })
                : -1; //-1 to force the first "nextLoop()" call increment and return value at index 0
            const nextIndex = ++indexLoop % this.keys.length;

            return this[this.keys[nextIndex]];
        },
        enumerable: false
    }
});

export default iterableObject;
