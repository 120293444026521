<template>
    <div class="swiper" ref="swiper">
        <div class="swiper-wrapper">
            <slot></slot>
        </div>
        <slot name="after"></slot>
    </div>
</template>

<script>
// Import Swiper Vue.js components
import Swiper, { Pagination, Navigation, HashNavigation, EffectCoverflow, Keyboard, FreeMode } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/pagination/pagination.scss';

export default {
    name: 'Swiper',

    data() {
        return {
            swiper: null,
            hasLoaded: false
        };
    },

    props: {
        options: {
            type: Object
        },
        keepOnDestroy: Boolean //e.g. product details - before page change swiped gallery flicker with random offset image
    },

    computed: {
        modules() {
            let modules = [];
            if (this.options.pagination) {
                modules.push(Pagination);
            }
            if (this.options.navigation) {
                modules.push(Navigation);
            }
            if (this.options.hashNavigation) {
                modules.push(HashNavigation);
            }
            if (this.options.coverflowEffect) {
                modules.push(EffectCoverflow);
            }
            if (this.options.keyboard) {
                modules.push(Keyboard);
            }
            if (this.options.freeMode) {
                modules.push(FreeMode);
            }

            return modules;
        }
    },

    methods: {
        isHidden() {
            if (this._isMounted) {
                const elementToCheck = this.$el.closest('.media-only') || this.$el;
                return getComputedStyle(elementToCheck).display === 'none';
            }
        },
        preventJumpToLastSlide() {
            let slidesBefore = this.swiper.slides.length;
            let increased;
            let beforeObserverTranslate;
            let realIndex;

            this.swiper.on('slidesLengthChange', () => {
                increased = slidesBefore < this.swiper.slides.length;
                if (increased) {
                    realIndex = this.swiper.realIndex;
                    beforeObserverTranslate = this.swiper.getTranslate();
                }
            });

            this.swiper.on('observerUpdate', () => {
                if (increased) {
                    this.swiper.slideTo(realIndex, 0);
                    this.swiper.setTranslate(beforeObserverTranslate);
                }
            });
        },
        init() {
            if (!this.isHidden()) {
                this.destroy(); //needed for proper pagination. Otherwise when desktop -> mobile -> desktop -> mobile: pagination is broken

                this.swiper = new Swiper(this.$refs.swiper, {
                    modules: this.modules,
                    simulateTouch: false, //fixes the touch emulating devices
                    ...this.options
                });

                if (this.options.observer) {
                    this.preventJumpToLastSlide();
                }

                this.hasLoaded = true;

                this.$emit('load', this.swiper);

                Object.keys(this.$listeners).forEach(listener => {
                    this.swiper.on(listener, () => {
                        this.$emit(listener, this.swiper);
                    });
                });
            }
        },
        destroy() {
            this.swiper && !this.keepOnDestroy && this.swiper.destroy(false, false);
        }
    },

    watch: {
        '$root.isMobile'(isMobile) {
            if (isMobile) {
                this.$nextTick(() => {
                    this.init();
                });
            }
        }
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        this.destroy();
    }
};
</script>

<style scoped lang="scss">
::v-deep {
    .swiper-wrapper {
        display: flex;
    }

    .swiper-slide {
        touch-action: pan-y; //prevents from passive listener error on touchstart (on desktop devices)
    }

    .swiper-pagination {
        position: absolute; //prevents from CLS on mount
    }
}
</style>
