/**
 * Represents a block
 * @class
 */
export default class Block {
    /**
     * @constructor
     * @param {BlockType} type
     * @param {string} text
     * @param {string} imageUrl
     * @param {Link} link
     * @param {string} productCategory
     * @param {number} rows
     * @param {number} columns
     */
    constructor({ type, text, imageUrl, link, productCategory, rows, columns }) {
        Object.assign(this, {
            type,
            text,
            imageUrl,
            link,
            productCategory,
            rows,
            columns
        });
    }
}
