import Vue from 'vue';
import getDeviceTrait from '@/utils/getDeviceTrait';
import MediaOnly from '@/components/MediaOnly';

/**
 !important. I highly recommend using this.$root.isMobile in Vue components,
 since the $root computed property is cached. Otherwise calling local component's
 isMobile computed property will be cached only within component's scope.
 */

// noinspection
export default function(ctx, inject) {
    //noinspection
    const pluginOptions = {"breakpoints":{"xs":575.98,"sm":767.98,"md":991.98,"lg":1199.98,"xl":"Infinity"},"mobileThreshold":"md"};

    //nuxt.config.js breakpoints
    const {
        breakpoints, mobileThreshold
    } = pluginOptions;

    let userAgent = '';
    if (typeof ctx.req !== 'undefined') {
        userAgent = ctx.req.headers['user-agent']
    } else if (typeof navigator !== 'undefined') {
        userAgent = navigator.userAgent
    }

    //!Important part. Here combined layout usage probability is defined
    ctx.deviceTrait = getDeviceTrait(userAgent, breakpoints[mobileThreshold]);

    const reactorComponent = new Vue({
        data: () => ({
            currentBreakpoint: mobileThreshold,
            isPrint: false
        })
    });

    //breakpoint keys array, e.g. ['sm', 'xs']
    const keys = Object.keys(breakpoints);
    const values = keys.map(key => {
        if (breakpoints[key] === "Infinity") {
            breakpoints[key] = Infinity;
        }
        return breakpoints[key];
    }).sort((a,b) => a - b);

    //Sorting breakpoints from smallest to the biggest
    const keysSorted = keys.reduce((final, current) => {
        const index = values.indexOf(breakpoints[current]);
        final[index] = current;
        return final;
    }, []);

    //Generating media query strings
    const mediaQueries = [0, ...values].reduce((final, current, index, values) => {
        const isLast = (index + 1) === values.length || !isFinite(current);
        if (!isLast) {
            const minValue = index ? current + 0.01 : current;  //0.01 because breakpoint descriptions have 1/100 precision
            const maxValue = values[index + 1];
            final[keysSorted[index]] = `(min-width: ${minValue}px)` + (isFinite(maxValue) ? ` and (max-width: ${maxValue}px)` : '');
        }
        return final;
    }, {});

    const localMqMixin = {
        data() {
            return {
                mqListeners: {},
                mediaQueries,
                mobileThreshold,
                needsRerender: false,
                userAgent
            };
        },
        computed: {
            $mq() {
                return reactorComponent.currentBreakpoint;
            },
            isMobileMedia() {
                return keysSorted.indexOf(this.$mq) <= keysSorted.indexOf(this.mobileThreshold);
            },
            isMobile() {
                //client logic cannot return user-agent based results as done on a server side.
                //this will result in mobile header being shown simultaneously with bootstrap col-lg grid columns for instance
                if (process.server) {
                    const isMobile = this.$device.isMobileOrTablet;
                    this.$isDev && console.log('Server rendered on ' + (isMobile ? 'mobile' : 'desktop'));
                    return isMobile;
                }
                else {
                    const isMobile = this.$device.isMobileOrTablet;

                    if (this.needsRerender) {
                        return this.isMobileMedia;
                    }

                    //const isMobile = keysSorted.indexOf(this.$mq) <= keysSorted.indexOf(this.mobileThreshold);
                    this.$isDev && console.log('Client rendered on ' + (isMobile ? 'mobile' : 'desktop'));
                    return isMobile;
                }
            },
            mediaInconsistent() {
                return !this.isPrint && this.isMobile !== this.isMobileMedia;
            },
            isPrint() {
                return process.client && reactorComponent.isPrint;
            }
        },
        watch: {
            mediaQueries: {
                handler(mediaQueries) {
                    if (process.client) {
                        this.$isDev && console.warn("Registering media query listeners");

                        //removing previously attached listeners
                        for (const key in this.mqListeners) {
                            const listener = this.mqListeners[key];
                            window.matchMedia(key).removeListener(listener);
                            delete this.mqListeners[key];
                        }

                        //adding new listeners
                        for (const key in Object.assign(this.mediaQueries, { print: "print"})) {
                            const mediaQuery = this.mediaQueries[key];
                            const mql = window.matchMedia(mediaQuery);
                            const listener = ({ matches }) => {
                                if (matches && key !== "print") {
                                    reactorComponent.currentBreakpoint = key;
                                }
                                else if (key === "print") {
                                    reactorComponent.isPrint = matches;
                                }
                            };
                            mql.addListener(listener);
                            this.mqListeners[mediaQuery] = listener;

                            //Initial trigger
                            listener(mql);
                        }
                    }
                },
                immediate: true
            },
            mediaInconsistent: {
                immediate: true,
                handler(inconsistent) {
                    if (inconsistent && process.client) {
                        this.$isDev && console.warn("Media inconsistency. Will rerender")
                        if (this._isMounted) {
                            this.needsRerender = true;
                        }
                        this.$once('hook:mounted', () => {
                            this.needsRerender = true;
                        });
                    }
                }
            }
        }
    };

    Vue.component("MediaOnly", MediaOnly);

    ctx.app.head.style.push({
        cssText: '.media-only-hidden { display: none !important; }', type: 'text/css'
    });

    /*
        Attention! Vue-mq is designed to be a global Vue mixin.
        Personally I don't recommend using global mixins, because:
            - mixin lifecycle hooks  will be called for EVERY component!
            - computed properties will be cached only within each Vue component, not globally

        Thus, managed to add mixin only to nuxt Root component, which is actually the ctx.app
        For mediaQuery value just use $root.isMobile. This is one of edge cases when using $root
        property is a necessity.
    */
    const rootMixins = ctx.app.mixins || [];
    rootMixins.push(localMqMixin);
    ctx.app.mixins = rootMixins;
};
