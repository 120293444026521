<template>
    <svg-icon :name="filepath" :title="title" :desc="desc"
              class="icon-svg" :class="`icon-${id}`"/>
</template>

<script>
    export default {
        name: 'IconSvg',
        data () {
            return {}
        },
        props: {
            filepath: {
                type: String,
                required: true
            },
            title: {
                type: String,
                default: null
            },
            desc: {
                type: String,
                default: null
            }
        },
        computed: {
            id() {
                return this.filepath.replace(/^.*[\\/]/, '').slice(0, -4);
            }
        }
    }
</script>
