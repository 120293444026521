import Vue from 'vue';
import vuexI18n from 'vuex-i18n/dist/vuex-i18n.umd.js';
import Translate from '@/components/Translate';

/*
    https://github.com/dkfbasel/vuex-i18n
 * Note!
 *   vuex-i18n was selected as an internationalization plugin due to it's simplicity and strong vuex coupling.
 * More popular vue-i18n and it's nuxt module expect to have multiple locales with the correspondent translations
 * being available before the plugin initialization, which is a different use case, since out application fetches
 * everything asynchronously. Any attempts to initialize plugin after translations fetch failed.
 */

export default function ({ app, store }, inject) {
    //registering on a client side
    Vue.use(vuexI18n.plugin, store, {
        warnings: false //Translate component doesn't provide params by calling a translation
    });

    //Our custom interpolation
    Vue.component('translate', Translate);

    //inject('i18n', Vue.i18n);
    //registering on a server side
    //app.i18n = Vue.i18n;
}
