/**
 * @class
 */
export default class ProductPriceInformation {
    /**
     * @constructor
     * @param {ProductPriceValues} [regularPriceValues]
     * @param {number} [minimumSurcharge]
     * @param {number} [maximumSurcharge]
     */
    constructor({ regularPriceValues, minimumSurcharge, maximumSurcharge }) {
        Object.assign(this, {
            regularPriceValues,
            minimumSurcharge,
            maximumSurcharge
        });
    }
}
