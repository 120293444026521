import { AxiosError } from 'axios';

export const ErrorTypes = {
    multiVoucherCode: 'MultiVoucherCode',
    dynamicTargetPlatform: 'DynamicTargetPlatform',
    accessCode: 'AccessCode',
    authToken: 'AuthToken'
};

/**
 * @class
 * @constructor
 * @param {AxiosError} error
 * @param {string} failedRoute
 */
export default class AjaxError {
    constructor(error, failedRoute) {
        const { response, request } = error;
        this.failedRoute = failedRoute;
        if (response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            this.statusCode = response.status;
            this.message = (response.data && response.data.message) || response.statusText;
            if (response.data && response.data.length) {
                /**
                 * @property
                 * @type {ErrorTypes} errorType?
                 */
                this.errorType = response.data[0].authorizationViolationAuthorizationType;
            }
        } else if (request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            this.statusCode = request.status || 500;
            this.message = request.responseText || error.message;
            this.errorType = null;
        } else {
            this.message = error.message;
        }
    }

    get isNotFound() {
        return this.statusCode === 404;
    }

    get isServerError() {
        return this.statusCode >= 500;
    }

    get isUnauthorized() {
        return this.statusCode === 401;
    }
}
