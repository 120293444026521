/*
    https://nuxtjs.org/api/configuration-head
 */

/**
 * @typedef {Object} VueMetaLinkItem
 * @property {string} rel
 * @property {string} type
 * @property {string} href
 * @property {string} hid
 */

/**
 * @function
 * @param {string} icoUrl32x32
 * @param {string} svgUrl
 * @param {string} applePngUrl180x180
 * @returns {VueMetaLinkItem[]}
 */
export function getFaviconLinks(icoUrl32x32, svgUrl, applePngUrl180x180) {
    //https://evilmartians.com/chronicles/how-to-favicon-in-2021-six-files-that-fit-most-needs
    // be sure to provide all 3 types of favicon to properly override in the partner theme
    console.assert(icoUrl32x32 && svgUrl && applePngUrl180x180, 'One of required favicon urls is missing!');
    if (icoUrl32x32 && svgUrl && applePngUrl180x180) {
        return [
            { rel: 'icon', type: 'image/x-icon', href: icoUrl32x32, hid: 'favicon', sizes: 'any' },
            { rel: 'icon', type: 'image/svg+xml', href: svgUrl, hid: 'icon-svg' },
            { rel: 'apple-touch-icon', href: applePngUrl180x180, hid: 'apple-touch-icon' }
        ];
    }
}

/**
 * @typedef { Object } HrefLang
 * @property { String } url
 * @property { String } language
 * @property { Boolean } default
 */

export default {
    methods: {
        /**
         * @param {Object} pageData
         * @param {String} pageData.pageTitle
         * @param {String} pageData.metaDescription
         * @param {String} pageData.keywords
         * @param {Boolean} pageData.noindex
         * @param {String} [pageData.canonicalUrl]
         * @param {String} [pageData.imageUrl]
         * @param {HrefLang[]} [pageData.hrefLangs]
         * @param {Object} [schemaOrg]
         * @returns {{meta: *[], title}}
         */
        getHead(pageData = {}, schemaOrg) {
            const {
                pageTitle: title,
                metaDescription,
                keywords,
                noindex,
                canonicalUrl,
                imageUrl,
                hrefLangs
            } = pageData;
            const theme = this.$store.state.theme;
            const webP = this.$store.state.webP;
            const hasQueryParams = !!Object.keys(this.$route.query).filter(v => v.indexOf('x-') !== 0).length;
            const isMainShop = this.$store.getters.isMainShop;
            const isNettoVorteile = this.$store.state.ident === 'nettovorteilswelt';
            const isKurzInUrlaub = this.$store.state.ident === 'kurzinurlaub';
            const isAnimodNl = this.$store.state.ident === 'www.animod.nl';
            const nonIndexed =
                hasQueryParams || noindex || (!isMainShop && !isNettoVorteile && !isKurzInUrlaub && !isAnimodNl && !canonicalUrl);
            const classNames = [];

            if (theme) {
                classNames.push(`theme-${theme}`);
            }

            if (webP) {
                classNames.push('web-p');
            }

            const link = [];

            if (canonicalUrl) {
                link.push({ rel: 'canonical', href: canonicalUrl, hid: 'canonical' });
            }

            if (hrefLangs && Array.isArray(hrefLangs)) {
                [...hrefLangs] //shallow copy prevents from mutating the store during sort
                    .sort((hl1, hl2) => (hl1.default === hl2.default ? 0 : hl1.default ? -1 : 1))
                    .forEach(hl => {
                        link.push({
                            rel: 'alternate',
                            hreflang: hl.default ? 'x-default' : hl.language,
                            href: hl.url,
                            hid: 'hreflang'
                        });
                    });
            }

            const meta = {
                ...(title && { title }),

                link,

                meta: [
                    title && { property: 'og:title', content: title.replace(/\r?\n|\r/g, ''), hid: 'og:title' },
                    metaDescription && { name: 'description', content: metaDescription, hid: 'description' },
                    metaDescription && { property: 'og:description', content: metaDescription, hid: 'og:description' },
                    imageUrl && { property: 'og:image', content: imageUrl, hid: 'og:image' },
                    canonicalUrl && { property: 'og:url', content: canonicalUrl, hid: 'og:canonical' },
                    keywords && { name: 'keywords', content: keywords, hid: 'keywords' },
                    nonIndexed && {
                        name: 'robots',
                        content: 'noindex,follow,noarchive',
                        hid: 'robots'
                    },
                    { name: 'author', content: 'Animod GmbH', hid: 'author' }
                ].filter(Boolean),

                /**
                 * Why <html class> instead of a <body class>? Our themify mixin applies theme classname as a parent of themed selector.
                 * In order to be able to themify body tag we need to apply the classname to it's parent
                 */
                htmlAttrs: {
                    class: classNames.join(' ')
                },

                ...(schemaOrg && {
                    script: [
                        {
                            vmid: 'ldjson-schema',
                            json: schemaOrg,
                            type: 'application/ld+json'
                        }
                    ]
                })
            };

            // Required to tell what meta to set for the parent frame (if applicable)
            this.$root.$emit('iframeHeadChange', meta);

            return meta;
        }
    }
};
