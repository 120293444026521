<template>
    <picture class="image-container">
        <source
            v-if="retinaSrc"
            :srcset="retinaSrcDPI"
            media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)"
            ref="retinaSource"
        />
        <source v-if="webpSrc" :srcset="webpSrc" type="image/webp" ref="webpSource" />
        <!-- Img can still reference webp -->
        <img
            :src="src"
            :alt="alt"
            :height="height"
            :width="width"
            :loading="loadingMode"
            class="image-container__image img-fluid"
            :class="[
                { 'image-container__image--waiting': shouldAnimate },
                { 'image-container__image--contain': contain },
                staticClass,
                imgClass
            ]"
            ref="img"
            @error="trySubstitute"
        />
    </picture>
</template>

<script>
export default {
    name: 'ImageFluid',
    data() {
        return {
            complete: false
        };
    },

    props: {
        src: {
            type: String
            //required: true    //Unfortunately, can't be sure if image gets src
        },
        alt: {
            type: String,
            default() {
                return this.$t('ImageLazy.altDefault');
            }
        },
        /**
         * Image width and height should be required for the SEO purposes, but forcing them breaks component for already existing
         * image instances. These are nice to have specified
         */
        height: { type: [Number, String], default: 200 },
        width: {
            type: [Number, String],
            default: 200
        },
        webpSrc: String,
        retinaSrc: String,
        imgClass: String,
        animateLazy: {
            //set to false when using inside swiper loop
            type: Boolean,
            default: true
        },
        eager: Boolean,
        contain: Boolean //object-fit property, sometimes fixes the aspect-ratio artifacts
    },

    computed: {
        loadingMode() {
            return this.eager ? 'eager' : 'lazy';
        },
        retinaSrcDPI() {
            return `${this.retinaSrc} 2x`;
        },
        staticClass() {
            // Makes possible for img to inherit css class from root element (if set in the parent component)
            return this.$vnode.data.staticClass;
        },
        shouldAnimate() {
            return this.animateLazy && !this.complete && !this.eager;
        }
    },

    methods: {
        trySubstitute(e) {
            const selectedSrc = this.$getSafe(e, 'target.currentSrc');
            if (selectedSrc) {
                if (selectedSrc === this.retinaSrc && this.$refs.retinaSource) {
                    this.$refs.retinaSource.srcset = this.src;
                } else if (selectedSrc === this.webpSrc && this.$refs.webpSource) {
                    this.$refs.webpSource.srcset = this.src;
                }
            }
        }
    },

    mounted() {
        if (!this.$refs.img.complete) {
            this.$refs.img.addEventListener('load', () => {
                this.complete = true;
                this.$emit('load');
            });
        } else {
            this.complete = true;
            this.$emit('load');
        }
    }
};
</script>

<style scoped lang="scss">
.image-container {
    width: 100%;
    height: 100%;

    &__image {
        width: 100%;
        height: 100%;
        transition: opacity 0.3s linear;
        object-position: center;
        object-fit: cover;
        background-color: var(--body-bg);

        &--waiting {
            opacity: 0;
        }

        &--contain {
            object-fit: contain;
        }
    }
}
</style>
