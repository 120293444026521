export default {
    name: 'MediaOnly',
    props: {
        desktop: Boolean,
        mobile: Boolean,
        tag: {
            type: String,
            default: 'div'
        },
        displayProp: {
            type: String,
            default: 'block'
        },
        noWrap: Boolean
    },
    render(createElement) {
        const sizes = ['xs', 'sm', 'md', 'lg', 'xl'];
        const mobileThreshold = this.$root.mobileThreshold; //usually md
        const desktopMinSize = sizes[sizes.indexOf(mobileThreshold) + 1];
        const hideOnDesktopClass = `d-${desktopMinSize}-none`; //e.g. d-lg-none
        const hideOnMobileClasses = `d-none d-${desktopMinSize}-${this.displayProp}`; //e.g. d-none d-lg-grid
        const showAlwaysWithDisplayClass = `d-${this.displayProp}`;
        const showAlways = !this.desktop && !this.mobile;
        const hideDesktop = this.$store.state.layout === 'mobile' && this.desktop;
        const defaultSlot = this.$slots.default || [];

        if (hideDesktop) {
            return createElement(false);
        }

        if (this.noWrap && defaultSlot.length === 1) {
            defaultSlot.forEach(child => {
                if (!child.isComment && child.tag) {
                    child.data.class = {
                        ...child.data.class,
                        [hideOnDesktopClass]: this.mobile,
                        [hideOnMobileClasses]: this.desktop,
                        [showAlwaysWithDisplayClass]: showAlways
                    };
                }
            });
            return defaultSlot;
        }

        return createElement(
            this.tag,
            {
                class: {
                    'media-only': true,
                    [hideOnDesktopClass]: this.mobile,
                    [hideOnMobileClasses]: this.desktop,
                    [showAlwaysWithDisplayClass]: showAlways
                }
            },
            this.$slots.default
        );
    }
};
