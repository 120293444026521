import Vue from 'vue';

/**
 * @class
 * Adds the global Intersection Observer instance
 */
class IObserver {
    /**
     * @constructor
     */
    constructor () {
        this.observer = new IntersectionObserver(this.callback.bind(this));
        this.listeners = [];
    }

    /**
     *
     * @param {Array<IntersectionObserverEntry>} entries
     * @param observer
     */
    callback(entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const listener = this.listeners.find(listener => listener.target === entry.target);
                if (listener) {
                    listener.handler();
                }
                this.unobserve(entry.target);
            }
        });
    }

    /**
     * @method
     * @param {HTMLElement} el
     * @param {Function} handler
     */
    add(el, handler) {
        console.assert(handler, 'No Intersection Observer handler defined');

        this.observer.observe(el);
        this.listeners.push({
            target: el,
            handler
        });
    }

    /**
     * @method
     * @param {HTMLElement} element
     */
    unobserve(element) {
        const listenerIndex = this.listeners.findIndex(listener => listener.target === element);
        if (~listenerIndex) {
            this.listeners.splice(listenerIndex, 1);
        }
        this.observer.unobserve(element);
    }
}

Vue.prototype.$intersectionObserver = new IObserver();
