<template>
    <span v-if="hotelClassification.classificationVisible" class="classification" :class="{ inline }">
        <IconSvg
            v-for="(star, index) in hotelClassification.classificationValue"
            filepath="star.svg"
            class="star-svg"
            :key="'star' + index"
        />

        <span v-if="hotelClassification.classificationSuperior" class="superior" v-tooltip="inline ? 'Superior' : ''">
            {{ superiorText }}
        </span>

        <span
            v-if="hotelClassification.estimatedClassification"
            class="animod-stars"
            :class="{ below: estimateBelowStars }"
            v-tooltip="estimatedTooltip"
        >
            {{ animodStarsText }}
        </span>
    </span>
</template>

<script>
export default {
    name: 'Classification',
    data() {
        return {
            estimatedTooltip: this.$t('HotelDetails.classification.estimatedText')
        };
    },
    props: {
        hotelClassification: {
            type: Object,
            required: true
        },
        estimateBelowStars: Boolean,
        inline: Boolean
    },
    computed: {
        animodStarsText() {
            return this.inline
                ? this.$t('HotelDetails.classification.animodStars').replace('-', '\n')
                : this.$t('HotelDetails.classification.animodStars');
        },
        superiorText() {
            return this.inline ? 'S' : this.$t('HotelDetails.classification.superior');
        }
    }
};
</script>

<style scoped lang="scss">
.classification {
    font-size: inherit;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 0.5em;
    margin-top: 2px;

    .star-svg {
        width: 1em;
        height: 1em;
        fill: currentColor;

        &:not(:last-of-type) {
            margin-right: 0.15em;
        }
    }

    .superior {
        margin-left: 5px;
        font-size: 0.6em;
        text-decoration: none;
    }

    .animod-stars {
        position: relative;
        top: 0;
        white-space: nowrap;
        margin-left: 5px;

        &.below {
            position: absolute;
            top: 1.75em;
            line-height: 1.5em;
            font-size: 0.6em;
            margin-left: 0;
        }
    }

    &.inline {
        .animod-stars {
            white-space: normal;
            max-width: 4em;
            display: block;
            line-height: 1;
            font-size: 12px;
            text-align: center;
            margin-left: 3px;
        }

        .superior {
            font-size: 1em;
            line-height: normal;
        }
    }
}
</style>
