import Vue from 'vue';
import IconBase from '@/components/IconBase';

/**
 * Our wrapper around svg-icon component with necessary class bindings
 * These classes are required for styling from icons scss partial
 */

Vue.component('IconSvg', IconBase);





