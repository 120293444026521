<template>
    <div class="error-container">
        <Teaser :backgroundUrl="teaserUrl">
            <DetailsTeaser :title="title" class="category-teaser" />
        </Teaser>
        <div class="container">
            <slot></slot>

            <div class="row align-items-center py-3 py-lg-5">
                <div class="col-12 col-lg-8">
                    <h2 class="title">
                        {{ title }}
                    </h2>

                    <slot name="text"> </slot>
                </div>
                <div class="col-12 col-lg-4 text-right">
                    <slot name="button">
                        <nuxt-link
                            id="returnBtn"
                            to="/"
                            class="btn btn-info btn-lg"
                            @click.native.prevent="tryStartPage"
                        >
                            {{ buttonText }}
                        </nuxt-link>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Teaser from '@/components/teasers/Teaser';
import DetailsTeaser from '@/components/teasers/DetailsTeaser';

export default {
    name: 'ErrorContainer',
    components: { DetailsTeaser, Teaser },
    props: {
        title: String,
        teaserUrl: {
            type: String
        }
    },
    computed: {
        hasTranslations() {
            return this.$i18n && this.$i18n.localeExists(this.$store.state.language);
        },
        buttonText() {
            return this.hasTranslations ? this.$t('Generic.toStartPage') : 'zur Startseite';
        }
    },
    methods: {
        tryStartPage() {
            location.href = '/';
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/mixins';

.error-container {
    background-color: var(--body-bg);

    .title {
        font-size: 20px;
        font-weight: 700;

        @include mixins.mobile-only {
            text-transform: uppercase;
        }
    }

    .btn-info {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        padding: 18px;
        line-height: 1em;
    }
}
</style>
