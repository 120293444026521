<template>
    <Grid :grid="pwaGrid" class="app-promo-grid d-lg-none" />
</template>

<script>
import Vue from 'vue';
import Grid from '@/components/grid/Grid';
import GridClass from '@/models/Grid';
import Banner from '@/components/banners/PWABanner';

export default {
    name: 'PWABannerGrid',
    components: { Grid },
    computed: {
        pwaGrid() {
            //https://css-tricks.com/creating-vue-js-component-instances-programmatically/
            const BannerClass = Vue.extend(Banner);
            const bannerComponent = new BannerClass();
            bannerComponent.$mount();
            return new GridClass(this.$t('pwa.banner.title'), [], bannerComponent.$el.outerHTML);
        }
    }
};
</script>

<style lang="scss" scoped></style>
