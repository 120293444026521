import MultiClass from '@/models/pre-ts/MultiClass';
import Product from '@/models/pre-ts/product/Product';
import ValueVoucher from '@/models/pre-ts/product/ValueVoucher';

/**
 * @class
 */
export default class Favorite extends MultiClass(Product, ValueVoucher) {
    /**
     * @constructor
     * @param {string} favoriteId
     * @param {ProductType} productType
     * @param {number} productId
     * @param {string} created
     * @param {number} [customerId]
     */
    constructor({ favoriteId, customerId, productType, productId, created, ...rest }) {
        super(rest);
        Object.assign(this, {
            favoriteId,
            productId,
            productType,
            customerId,
            created
        });
    }
}
