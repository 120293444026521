import Vue from 'vue';
import utils from '@/utils';

export default ({ app }, inject) => {
    Object.keys(utils).forEach(util => {
        //Vue.prototype[util] = utils[util].bind ? utils[util].bind(Vue) : utils[util]
        inject(util, utils[util].bind ? utils[util].bind(Vue) : utils[util]);
    });
}



