export default function (context) {
    const theme = context.store.state.theme;
    const themePrefix = theme ? theme + '/' : '';
    const stateLayout = context.store.state.layout;

    if (context.route.name === 'accessCode') {
        context.layout = themePrefix + 'auth';
        if (stateLayout !== 'auth') {
            context.store.commit('setLayout', 'auth');
        }
        return;
    }
    if (context.isDesktop || !context.deviceTrait || context.deviceTrait.hasCombinedLayout) {
        context.layout = themePrefix + 'combined';
        if (stateLayout !== 'combined') {
            context.store.commit('setLayout', 'combined');
        }
    } else {
        context.layout = themePrefix + 'mobile';
        if (stateLayout !== 'mobile') {
            context.store.commit('setLayout', 'mobile');
        }
    }
}
