import iterableObject from '@/utils/iterableObject';

const priceModes = {
    perPerson: {
        property: 'minimumPerPersonPrice',
        cssClass: 'per-person-price',
        enabled: true
    },
    package: {
        property: 'minimumPrice',
        cssClass: 'package-price',
        enabled: true
    },
    perNight: {
        property: 'minimumPerPersonPerNightPrice',
        cssClass: 'per-person-per-night-price',
        enabled: true
    }
};

Object.setPrototypeOf(priceModes, iterableObject);

export default priceModes;
