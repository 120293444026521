//if no currency sign is needed: price.toLocaleString('de-DE', { minimumFractionDigits: 2 });

/**
 * @param {String, Number} value
 * @param {Boolean} [hideFractions]
 * @param {Object} [options]    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#syntax
 * @return {string|*}
 */
function formatPrice(value, hideFractions, options = {}) {
    if (typeof value !== 'number') {
        return value;
    }
    let formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        // next options do work in combination only
        minimumFractionDigits: hideFractions ? 0 : 2,
        maximumFractionDigits: hideFractions ? 0 : 2,
        ...options //
    });

    const formatted = formatter.format(value / 100);
    if (hideFractions) {
        return formatted.replace(/\s/g, '');
    }
    return formatted;
}

export default formatPrice;
