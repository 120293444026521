function parseQuery(query) {
    const res = {};

    if (!query) {
        return res;
    }

    query = query.trim().replace(/^(\?|#|&)/, '');

    query.split('&').forEach(function (param) {
        const parts = param.replace(/\+/g, ' ').split('=');
        const key = decodeURIComponent(parts.shift());
        const val = parts.length > 0 ? decodeURIComponent(parts.join('=')) : null;

        if (res[key] === undefined) {
            res[key] = val;
        } else if (Array.isArray(res[key])) {
            res[key].push(val);
        } else {
            res[key] = [res[key], val];
        }
    });

    return res;
}

const linkMethods = {
    product: (link, ident) => {
        const { productId, webnames = [], queryString: query, directBooking: directBooking} = link;

        const [firstWebName, secondWebName] = webnames;

        if (secondWebName) {
            let suffix = ident === 'www.animod.nl' ? '-nl' : '';
            return {
                name: (directBooking ? 'directBookingDetails' : 'hotelProductDetails') + suffix,
                params: {
                    hotelWebname: firstWebName,
                    productId,
                    webname: secondWebName
                },
                query
            };
        }
        return {
            name: 'productDetails',
            params: {
                webname: firstWebName,
                productId
            },
            query
        };
    },
    voucher: link => {
        const { webnames = [], valueVoucherId, queryString: query } = link;
        const [webname = '---'] = webnames;
        return {
            name: 'voucherDetails',
            params: {
                valueVoucherId,
                webname
            },
            query
        };
    },
    hotel: link => {
        const { webnames = [], hotelId, queryString: query } = link;
        const [webname = '---'] = webnames;
        return {
            name: 'hotelDetails',
            params: {
                hotelId,
                webname
            },
            query
        };
    },
    //url: link => link.url,
    page: link => {
        const { page: category, queryString, search: searchTag } = link;
        //Search page
        if (searchTag || queryString) {
            let query;
            if (queryString) {
                query = parseQuery(queryString);
            }
            return {
                name: 'searchResult',
                params: {
                    searchTag
                },
                query
            };
        }
        //Category page
        return {
            name: 'category',
            params: {
                category
            }
        };
    },
    content: function (link) {
        return this.page(link);
    }
};

/**
 * @typedef {String} LinkType
 */

/**
 * @enum {LinkType}
 */
const LinkType = Object.keys(linkMethods).reduce((types, key) => {
    types[key] = key;
    return types;
}, {});

export default {
    methods: {
        /**
         * Constructs router-link :to param depending on the provided type
         * @param {Object} link
         * @returns {Object|String} router-link :to prop object
         */
        getLink(link, ident) {
            const { productId, page, hotelId, valueVoucherId, url, search } = link;

            if (productId) {
                return linkMethods.product(link, ident);
            } else if (page || search) {
                /*else if (url) {
                return linkMethods.url(link);
            }*/
                return linkMethods.page(link);
            } else if (hotelId) {
                return linkMethods.hotel(link);
            }
            //webshop platform specific
            else if (valueVoucherId) {
                return linkMethods.voucher(link);
            } else if (url && url.startsWith('/')) {
                return url;
            } else {
                return '#';
            }
        },
        /**
         * Quicker link extraction to prevent multiple if-else run
         * @param {Object} link
         * @param {LinkType} type
         * @returns {Object|String} router-link :to prop object
         */
        getLinkByType(link, type) {
            return linkMethods[type](link);
        }
    }
};
