import { render, staticRenderFns } from "./GridTitle.vue?vue&type=template&id=2ed90b1f&functional=true"
import script from "./GridTitle.vue?vue&type=script&lang=js"
export * from "./GridTitle.vue?vue&type=script&lang=js"
import style0 from "./GridTitle.vue?vue&type=style&index=0&id=2ed90b1f&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports