import CookieManager from '@/models/CookieManager';

class MemoryStorage {
    constructor() {
        this._data = {};
    }

    getItem(key) {
        return this._data.hasOwnProperty(key) ? this._data[key] : null;
    }

    setItem(key, value) {
        return (this._data[key] = String(value));
    }

    removeItem(key) {
        return delete this._data[key];
    }

    clear() {
        return (this._data = {});
    }
}

const supportsStorage = name => {
    try {
        const storage = window[name] || document[name];
        storage.setItem('test-key', '1');
        const value = storage.getItem('test-key');
        storage.removeItem('test-key');
        return value === '1';
    } catch (e) {
        return false;
    }
};

const fallbackStorage = inject => {
    if (process.client) {
        if (!supportsStorage('localStorage')) {
            Object.defineProperty(window, 'localStorage', {
                value: new MemoryStorage()
            });
            inject('noLocalStorage', true);
        }
        if (!supportsStorage('sessionStorage')) {
            Object.defineProperty(window, 'sessionStorage', {
                value: new MemoryStorage()
            });
            inject('noSessionStorage', true);
        }
    }
};

export default ({ app, req, res, route, $axios, $config }, inject) => {
    inject('cookies', new CookieManager(route.query, req, res, $axios.setHeader, $config));

    fallbackStorage(inject);
};
